import React, {Fragment} from "react";
import * as Note from "tonal-note";
import {inject, observer} from "mobx-react";
import {
    BATCH_COUNTER_LABEL_BABY,
    BATCH_COUNTER_LABEL_BABY3,
    BATCH_MSG,
    MESSAGE_TYPE,
    MESSAGE_TYPE_LABEL, MSG_WITH_CHANNEL,
} from "../model/constants";
import {
    offset,
    offsetChannel,
    offsetController, offsetCounter,
    offsetEndValue,
    offsetMessageType,
    offsetStartValue,
    offsetStep, presetValue
} from "../model/preset";
import "./ActionEditor.css";
import {
    CC_RESEND_COUNTER,
    CC_SEND_END,
    CC_STEP, getCCMessageStyle,
    getPCMessageStyle,
    PC_RESEND_COUNTER,
    PC_SEND_END,
    PC_STEP
} from "../model";
import {global_offset} from "../model/global";

class Message extends React.Component {

/*
    constructor(props) {
        super(props);
        this.state = {
            // pc_style: getPCMessageStyle(props.preset, props.msg_index),
            cc_style: getCCMessageStyle(props.preset, props.msg_index)
        };
    }
*/

/*
    static getDerivedStateFromProps(props, state) {
        return {
            pc_style: getPCMessageStyle(props.preset, props.msg_index),
            cc_style: getCCMessageStyle(props.preset, props.msg_index)
        };
    }
*/

    setPCStyle = (e) => {
        const v = e.target.value;
        this.props.updateCallback('pcstyle', this.props.msg_index, v);
        // this.setState(
        //     {pc_style: parseInt(v, 10)},
        //     () => this.props.updateCallback('pcstyle', this.props.msg_index, v)
        // );
    };

    setCCStyle = (e) => {
        const v = e.target.value;
        this.props.updateCallback('ccstyle', this.props.msg_index, v);
        // this.setState(
        //     {cc_style: parseInt(v, 10)},
        //     () => this.props.updateCallback('ccstyle', this.props.msg_index, v)
        // );
    };

    render() {

        const {msg_index, preset, updateCallback, deleteCallback, moveUpCallback, moveDownCallback, clockState} = this.props;

        // if (global.dev) console.log("Message", msg_index, preset, updateCallback, deleteCallback, moveUpCallback, moveDownCallback, clockState);
        // if (global.dev) console.log("Message preset", preset[offsetEndValue(msg_index)]);

        // const msg_type = parseInt(preset[offsetMessageType(msg_index)], 10);
        const msg_type = presetValue(preset, offsetMessageType(msg_index));

        // channel value is 1..16 displayed as 1..16
        const channel = (<div><select value={preset[offsetChannel(msg_index)]} onChange={(e) => updateCallback('channel', msg_index, e.target.value)}>
                            {Array.from(Array(16).keys()).map(i => <option key={i+1} value={i+1}>{i+1}</option>)}
                        </select></div>);

        const cc_style = getCCMessageStyle(preset, msg_index);
        const pc_style = getPCMessageStyle(preset, msg_index);
        if (global.dev) console.log(`Message.render pc_style=${pc_style}, cc_style=${cc_style}`);

        const controller = <input type="text" size="3"
                                  value={preset[offsetController(msg_index)]}
                                  onChange={(e) => updateCallback('controller', msg_index, e.target.value)}
                                  onBlur={(e) => updateCallback('controller', msg_index, e.target.value, true)} />;
        const start = <input type="text" size="3"
                             value={preset[offsetStartValue(msg_index)]}
                             onChange={(e) => updateCallback('startvalue', msg_index, e.target.value, pc_style, cc_style)}
                             onBlur={(e) => updateCallback('startvalue', msg_index, e.target.value, pc_style, cc_style, true)} />;
        const end = <input type="text" size="3"
                           value={preset[offsetEndValue(msg_index)]}
                           onChange={(e) => updateCallback('endvalue', msg_index, e.target.value, pc_style, cc_style)}
                           onBlur={(e) => updateCallback('endvalue', msg_index, e.target.value, pc_style, cc_style, true)}/>;
        const step = <input type="text" size="3"
                            value={preset[offsetStep(msg_index)]}
                            onChange={(e) => updateCallback('step', msg_index, e.target.value, pc_style, cc_style)}
                            onBlur={(e) => updateCallback('step', msg_index, e.target.value, pc_style, cc_style, true)} />;

        const step_raw = presetValue(preset, offsetStep(msg_index));
        const step_delay_ms = ((step_raw ? step_raw : 0) + 1) * 5;

        //TODO: counter is 0-index or not ?
        const counter = <div>
                            <select value={preset[offsetCounter(msg_index)]} onChange={(e) => updateCallback('counter', msg_index, e.target.value)}>
                                {Array.from(Array(128).keys()).map(i => <option key={i} value={i}>{i}</option>)}
                            </select>
                        </div>;

        const batch_counter = <select value={preset[offsetController(msg_index)]} onChange={(e) => updateCallback('controller', msg_index, e.target.value)}>
                                  {Array.from(Array(this.props.baby3 ? 21 : 9).keys()).map(i => <option key={i} value={i}>{this.props.baby3 ? BATCH_COUNTER_LABEL_BABY3[i] : BATCH_COUNTER_LABEL_BABY[i]}</option>)}
                              </select>;

        // if (global.dev) console.log(`Message.render: pc_style=${this.state.pc_style} msg_type=${msg_type} start=${preset[offsetStartValue(msg_index)]} end=${preset[offsetEndValue(msg_index)]} step=${preset[offsetStep(msg_index)]}`);

        let f;
        switch (msg_type) {
            case MESSAGE_TYPE.PC : {
                let pc_fields;
                switch (pc_style) {
                    case PC_SEND_END:           // send single value
                        pc_fields = <Fragment>
                            <span className="edit-value-label">Value {end}</span>
                        </Fragment>;
                        break;
                    case PC_RESEND_COUNTER:     // send counter
                        pc_fields = null;
                        break;
                    case PC_STEP:               // count & send
                    default:
                        pc_fields = <Fragment>
                            <span className="edit-value-label">Start {start}</span>
                            <span className="edit-value-label">End {end}</span>
                            <span className="edit-value-label">Step {step}</span>
                        </Fragment>;
                        break;
                }
                f = <Fragment>
                    {channel}
                    <div className="col-span-4 edit-data">
                        <select value={pc_style} onChange={this.setPCStyle} className="pcstyle">
                            <option value={PC_SEND_END}>send single value</option>
                            <option value={PC_STEP}>count &amp; send</option>
                            <option value={PC_RESEND_COUNTER}>send counter</option>
                        </select>
                        {pc_fields}
                    </div>
                    {counter}
                </Fragment>;
                break;
            }
            case MESSAGE_TYPE.CC_toggle : {
                let cc_fields;
                switch (cc_style) {
                    case CC_SEND_END:           // send single value
                        cc_fields = <Fragment>
                            <span className="edit-value-label">Value {end}</span>
                        </Fragment>;
                        break;
                    case CC_RESEND_COUNTER:     // send counter
                        cc_fields = null;
                        break;
                    case CC_STEP:               // count & send
                    default:
                        cc_fields = <Fragment>
                            <span className="edit-value-label">Start {start}</span>
                            <span className="edit-value-label">End {end}</span>
                            <span className="edit-value-label">Step {step}</span>
                        </Fragment>;
                        break;
                }
                f = <Fragment>
                    {channel}
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Controller {controller}</span>
                        <select value={cc_style} onChange={this.setCCStyle} className="ccstyle">
                            <option value={CC_SEND_END}>send single value</option>
                            <option value={CC_STEP}>count &amp; send</option>
                            <option value={CC_RESEND_COUNTER}>send counter</option>
                        </select>
                        {cc_fields}
                    </div>
                    {counter}
                </Fragment>;
                break;
            }
            case MESSAGE_TYPE.CC_return :
                f = <Fragment>
                    {channel}
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Controller {controller}</span>
                        <span className="edit-value-label">Value 1 {start}</span>
                        <span className="edit-value-label">Value 2 {end}</span>
                        <span className="edit-value-label min-space">Delay</span>
                        <input className="slider width-100" type="range" min={0} max={127} value={preset[offsetStep(msg_index)]}
                               onChange={(e) => updateCallback('step', msg_index, e.target.value)}/>
                        <span className="edit-value-value">{step_delay_ms}&nbsp;ms</span>
                    </div>
                    <div/>
                </Fragment>;
                break;
            case MESSAGE_TYPE.note :
                f = <Fragment>
                    {channel}
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label min-space">Note</span>
                        <select value={preset[offsetController(msg_index)]}
                                onChange={(e) => updateCallback('controller', msg_index, e.target.value)} className="notes">
                            {Array.from(Array(127).keys()).map(
                                i => {
                                    let n = Note.fromMidi(i, true);
                                    return <option key={i} value={i}>{i}: {n}</option>
                                })}
                        </select>
                        <span className="edit-value-label">Velocity {end}</span>
                    </div>
                    <div/>
                </Fragment>;
                break;
            case MESSAGE_TYPE.note_return :
                f = <Fragment>
                    {channel}
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label min-space">Note</span>
                        <select value={preset[offsetController(msg_index)]}
                                onChange={(e) => updateCallback('controller', msg_index, e.target.value)} className="notes">
                            {Array.from(Array(127).keys()).map(
                                i => {
                                    let n = Note.fromMidi(i, true);
                                    return <option key={i} value={i}>{i}: {n}</option>
                                })}
                        </select>
                        <span className="edit-value-label">Velocity {start}</span>
                        <span className="edit-value-label min-space">Delay</span>
                        <input className="slider width-100" type="range" min={0} max={127}
                               value={preset[offsetStep(msg_index)]}
                               onChange={(e) => updateCallback('step', msg_index, e.target.value)}/>
                        <span className="edit-value-value">{step_delay_ms}&nbsp;ms</span>
                    </div>
                    <div></div>
                </Fragment>;
                break;
            case MESSAGE_TYPE.set_tempo :
            case MESSAGE_TYPE.start_clock :
            case MESSAGE_TYPE.stop_clock :
            case MESSAGE_TYPE.toggle_clock :
                if (clockState) {
                    f = <Fragment>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </Fragment>;
                } else {
                    f = <Fragment>
                        <div className="col-span-4 warning self-align-center">MIDI Clock must be enabled in the global settings.</div>
                        <div/>
                        <div/>
                    </Fragment>;
                }
                break;
            case MESSAGE_TYPE.strymon_bank :
                f = <Fragment>
                    {channel}
                    <div>
                        <select value={preset[offsetEndValue(msg_index)]}
                                onChange={(e) => updateCallback('endvalue', msg_index, e.target.value)}>
                            <option value={0}>bank down</option>
                            <option value={1}>bank up</option>
                        </select>
                    </div>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </Fragment>;
                break;
            case MESSAGE_TYPE.batch_count_dec :     // Decrement batch counter
            case MESSAGE_TYPE.batch_dec_fire :      // Decrement batch and fire action
                f = <Fragment>
                    <div/>
                    {/*{batch_counter}*/}
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Counter: {batch_counter}</span>
                        {/*<span className="edit-value-label">Dec by {end}</span>*/}
                        <span className="edit-value-label min-space">Dec by</span>
                        <select value={preset[offsetEndValue(msg_index)]} onChange={(e) => updateCallback('endvalue', msg_index, e.target.value)}>
                            {Array.from(Array(128).keys()).map(i => <option key={i} value={i}>{i}</option>)}
                        </select>
                    </div>
                    <div/>
                </Fragment>;
                break;
/*
            case MESSAGE_TYPE.batch_dec_fire :      // Decrement batch and fire action
                f = <Fragment>
                    <div/>
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Counter: {batch_counter}</span>
                        <span className="edit-value-label">Dec by {end}</span>
                    </div>
                    <div/>
                </Fragment>;
                break;
*/
            case MESSAGE_TYPE.batch_count_inc :     // Increment batch counter
            case MESSAGE_TYPE.batch_inc_fire :      // Increment batch counter and fire action
                f = <Fragment>
                    <div/>
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Counter: {batch_counter}</span>
                        {/*<span className="edit-value-label">Inc by {start}</span>*/}
                        <span className="edit-value-label min-space">Inc by</span>
                        <select value={preset[offsetStartValue(msg_index)]} onChange={(e) => updateCallback('startvalue', msg_index, e.target.value)}>
                            {Array.from(Array(128).keys()).map(i => <option key={i} value={i}>{i}</option>)}
                        </select>
                    </div>
                    <div/>
                </Fragment>;
                break;
/*
            case MESSAGE_TYPE.batch_inc_fire :      // Increment batch counter and fire action
                f = <Fragment>
                    <div/>
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Counter: {batch_counter}</span>
                        <span className="edit-value-label">Inc by {start}</span>
                    </div>
                    <div/>
                </Fragment>;
                break;
*/
            case MESSAGE_TYPE.batch_count_set :     // Set batch counter to a number
            case MESSAGE_TYPE.batch_set_fire :      // Set batch to number and fire action
                f = <Fragment>
                    <div/>
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Counter: {batch_counter}</span>
                        {/*<span className="edit-value-label">Set to {start}</span>*/}
                        <span className="edit-value-label min-space">Set to</span>
                        <select value={preset[offsetStartValue(msg_index)]} onChange={(e) => updateCallback('startvalue', msg_index, e.target.value)}>
                            {Array.from(Array(16).keys()).map(i => <option key={i} value={i}>{i}</option>)}
                        </select>
                    </div>
                    <div/>
                </Fragment>;
                break;
/*
            case MESSAGE_TYPE.batch_set_fire :      // Set batch to number and fire action
                f = <Fragment>
                    <div/>
                    <div className="col-span-4 edit-data">
                        <span className="edit-value-label">Counter: {batch_counter}</span>
                        {/!*<span className="edit-value-label">Set to {start}</span>*!/}
                        <span className="edit-value-label min-space">Set to</span>
                        <select value={preset[offsetStartValue(msg_index)]} onChange={(e) => updateCallback('startvalue', msg_index, e.target.value)}>
                            {Array.from(Array(16).keys()).map(i => <option key={i+1} value={i}>{i}</option>)}
                        </select>
                    </div>
                    <div/>
                </Fragment>;
                break;
*/
            case MESSAGE_TYPE.no_action :
                f = <Fragment>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </Fragment>;
                break;
            default:
                if (global.dev) console.error("invalid msg_type", msg_type);
                f = <Fragment>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </Fragment>;
        }

        return (
            <Fragment>
                <div className="index">{msg_index + 1}</div>
                <div>
                    <select onChange={(event) => updateCallback('messagetype', msg_index, event.target.value)} value={msg_type}>
                        {Object.values(MESSAGE_TYPE).map((v, i) => <option key={i} value={v}>{MESSAGE_TYPE_LABEL[v]}</option>)}
{/*
                        <optgroup label="Channel Voice Messages">
                            <option value={MESSAGE_TYPE.PC}>{MESSAGE_TYPE_LABEL[MESSAGE_TYPE.PC]}</option>
                            <option value={MESSAGE_TYPE.CC_toggle}>{MESSAGE_TYPE_LABEL[MESSAGE_TYPE.CC_toggle]}</option>
                        </optgroup>
                        <optgroup label="batch">
                            <option value={MESSAGE_TYPE.batch_set_fire}>{MESSAGE_TYPE_LABEL[MESSAGE_TYPE.batch_set_fire]}</option>
                        </optgroup>
*/}
                    </select>
                </div>
                {f}
                <div>
                    <button type="button" className="btn btn-small btn-danger" onClick={() => deleteCallback(msg_index)}>delete</button>
                </div>
                <div>{moveDownCallback && <button type="button" className="btn btn-small btn-warning" onClick={() => moveDownCallback(msg_index)}>&#9660;</button>}</div>
                <div>
                    {moveUpCallback && <button type="button" className="btn btn-small btn-warning" onClick={() => moveUpCallback(msg_index)}>&#9650;</button>}
                    {/* start=<span>{presetValue(preset, offsetStartValue(msg_index))}</span>, end=<span>{presetValue(preset, offsetEndValue(msg_index))}</span>, step=<span>{presetValue(preset, offsetStep(msg_index))}</span> */}
                </div>
            </Fragment>
        );
    }
}

class ActionEditor extends React.Component {

    state = {
        refresh_messages: false
    };

    addMessage = () => {
        const {button, action} = this.props;
        this.props.appState.incNumberOfMessages(button, action);
        const msg_num = this.props.appState.getNumberOfMessages(button, action) - 1;
        let counter;
        if (msg_num === 0) {
            counter = 0;
        } else {
            counter = presetValue(this.props.appState.preset[button][action], offsetCounter(msg_num - 1)) + 1;
        }
        this.setParam(action, 'channel', msg_num, '1');             //TODO: define constant for default channel
        this.setParam(action, 'step', msg_num, '1');                //TODO: define constant for default step
        this.setParam(action, 'counter', msg_num, `${counter}`);    //TODO: define constant for default counter
        this.props.appState.setButtonDirty(this.props.button);
        this.props.appState.setPresetDirty(this.props.button);
    };

    deleteMessage = (message_number) => {
        this.props.appState.deleteMessage(this.props.button, this.props.action, message_number);
        this.props.appState.setButtonDirty(this.props.button);
        this.props.appState.setPresetDirty(this.props.button);
    };

    moveMessage = (message_number, new_number) => {
        this.props.appState.moveMessage(this.props.button, this.props.action, message_number, new_number);
        this.props.appState.setPresetDirty(this.props.button);
    };

    setParam = (action, param, message_number, str_value, pc_style = -1, cc_style = -1, fix_value = false) => {

        if (global.dev) console.log(`setParam(action=${action}, param=${param}, message_num=${message_number}, str_val=${str_value}, pc_style=${pc_style}, cc_style=${cc_style})`);

        // let value = Math.min(Math.max(0, parseInt(str_value, 10) || 0), 127);
        let value = parseInt(str_value, 10);
        if (str_value !== '' && isNaN(value)) {
            if (global.dev) console.log(`invalid value`, str_value);
            return;
        }

        // in MIDI value must be 0..127
        if (fix_value) {
            const v = str_value ? parseInt(str_value, 10) : 0;
            if (v > 127) {
                if (global.dev) console.log('value needs to be fixed', v);
                str_value = '127';
            } else if (v < 0) {
                if (global.dev) console.log('value needs to be fixed', v);
                str_value = '0';
            }
            if (global.dev) console.log('fixed value', str_value);
        }

        const P = this.props.appState.preset[this.props.button][action];

        if ((param !== 'pcstyle') && (param !== 'ccstyle')) {

            if (global.dev) console.log(`setParam: set ${param} to "${str_value}"`);

            P[offset(param, message_number)] = str_value;

        } else if (param === 'pcstyle') {

            if (global.dev) console.log('setParam PC STYLE');

            // If step == 0 then re-send current counter value when triggered, ignore start and end
            // Else if start == end AND step != 0, send END VALUE
            // Else check range and count up or down, update assigned counter

            /*
            if (global.dev) {
                const dstart = parseInt(P[offset('startvalue', message_number)], 10);
                let dend = parseInt(P[offset('endvalue', message_number)], 10);
                let int_start = parseInt(P[offsetStartValue(message_number)], 10);
                let int_end = parseInt(P[offsetEndValue(message_number)], 10);
                console.log("change PC style message", value, dstart, dend, int_start, int_end);    //TODO: delete after check
            }
            */

            // special case for PC
            switch (value) {
                case PC_SEND_END: {         // "send single value"
                    if (global.dev) console.log("change PC style: SEND END: set end=start");
                    // if start == end AND step != 0, send END VALUE
                    P[offsetEndValue(message_number)] = P[offsetStartValue(message_number)];
                    let int_step = presetValue(P, offsetStep(message_number));
                    if (int_step === 0) {
                        P[offsetStep(message_number)] = '1';
                    }
                    break;
                }
                case PC_RESEND_COUNTER:     // "send counter"
                    // if step == 0 then re-send current counter value when triggered, ignore start and end
                    P[offsetStep(message_number)] = '0';
                    break;
                case PC_STEP:               // "count & send"
                default: {
                    // else check range and count up or down, update assigned counter
                    let int_start = presetValue(P, offsetStartValue(message_number));
                    let int_end = presetValue(P, offsetEndValue(message_number));
                    if (int_start === int_end) {
                        const new_end = (int_start + 1) % 128;
                        P[offsetEndValue(message_number)] = new_end.toString(10);
                    }
                    P[offsetStep(message_number)] = '1';
                }
            }

        } else if (param === 'ccstyle') {

            if (global.dev) console.log('setParam CC STYLE');

            // special case for PC
            switch (value) {
                case CC_SEND_END: {
                    // if start == end AND step != 0, send END VALUE
                    P[offsetEndValue(message_number)] = P[offsetStartValue(message_number)];
                    let int_step = presetValue(P, offsetStep(message_number));
                    if (int_step === 0) {
                        P[offsetStep(message_number)] = '1';
                    }
                    break;
                }
                case CC_RESEND_COUNTER:
                    // if step == 0 then re-send current counter value when triggered, ignore start and end
                    P[offsetStep(message_number)] = '0';
                    break;
                case CC_STEP:
                default: {
                    // else check range and count up or down, update assigned counter
                    let int_start = presetValue(P, offsetStartValue(message_number));
                    let int_end = presetValue(P, offsetEndValue(message_number));
                    if (int_start === int_end) {
                        const new_end = (int_start + 1) % 128;
                        P[offsetEndValue(message_number)] = new_end.toString(10);
                    }
                    P[offsetStep(message_number)] = '1';
                }
            }
        }

        const msg_type = parseInt(P[offsetMessageType(message_number)], 10);

        // update linked values:
        if (msg_type === MESSAGE_TYPE.PC) {
            if (pc_style === PC_SEND_END) {
                // if start == end AND step != 0, send END VALUE
                if (global.dev) console.log('setParam: PC_SEND_END: set start=end', P[offsetStartValue(message_number)], P[offsetEndValue(message_number)]);
                P[offsetStartValue(message_number)] = P[offsetEndValue(message_number)];
                let int_step = presetValue(P, offsetStep(message_number));
                if (int_step === 0) {
                    if (global.dev) console.log('setParam: PC_SEND_END: set step=1');
                    P[offsetStep(message_number)] = '1';
                }
            }
        }

        // update linked values:
        if (msg_type === MESSAGE_TYPE.CC_toggle) {
            if (cc_style === CC_SEND_END) {
                // if start == end AND step != 0, send END VALUE
                if (global.dev) console.log('setParam: CC_SEND_END: set start=end', P[offsetStartValue(message_number)], P[offsetEndValue(message_number)]);
                P[offsetStartValue(message_number)] = P[offsetEndValue(message_number)];
                let int_step = presetValue(P, offsetStep(message_number));
                if (int_step === 0) {
                    if (global.dev) console.log('setParam: CC_SEND_END: set step=1');
                    P[offsetStep(message_number)] = '1';
                }
            }
        }

        // update linked values:
        if (BATCH_MSG.includes(msg_type)) {
            if (global.dev) console.log('setParam: BATCH_MSG');
            const max = this.props.appState.isBaby3() ? 20 : 9;
            if (presetValue(P, offsetController(message_number)) > max) {
                P[offsetController(message_number)] = '0';
            }
            // let int_start = presetValue(P, offsetStartValue(message_number));
            // let int_end = presetValue(P, offsetEndValue(message_number));
            // if (int_start === 0) P[offsetStartValue(message_number)] = '1';
            // if (int_end === 0) P[offsetEndValue(message_number)] = '1';
        }

        // update linked values:
        // if (msg_type === MESSAGE_TYPE.batch_set_fire) {
            // if (cc_style === CC_SEND_END) {
            //     if (global.dev) console.log('setParam: CC_SEND_END: set start and step');
            //     P[offsetStartValue(message_number)] = P[offsetEndValue(message_number)];
                // let int_step = presetValue(P, offsetStep(message_number));
                // if (int_step === 0) {
                //     P[offsetStep(message_number)] = '1';
                // }
            // }
        // }

        if (MSG_WITH_CHANNEL.includes(msg_type)) {
            // set channel to 1 if current value is 0
            if (presetValue(P, offsetChannel(message_number)) === 0) {
                if (global.dev) console.log('setParam: force MIDI channel to 1');
                P[offsetChannel(message_number)] = '1';
            }
        }

        this.setState({refresh_messages: !this.state.refresh_messages});
        this.props.appState.setPresetDirty(this.props.button);

    };

    render() {

        const {button, action} = this.props;   // action is one of ACTION
        const S = this.props.appState;
        const N = S.getNumberOfMessages(button, action);

        return (
            <div className="edit-action">

                {N > 0 &&
                <div className="edit-grid">
                    <div className="h index">#</div>
                    <div className="h">message type</div>
                    <div className="h">channel</div>
                    <div className="h col-span-4 edit-data">data</div>
                    <div className="h">counter</div>
                    <div/>
                    <div/>
                    <div/>
                    {Array.from(Array(N).keys()).map(i =>
                        <Message key={i} msg_index={i} preset={S.preset[button][action]}
                                 updateCallback={(param, message_number, str_value, pc_style = -1, cc_style = -1, fix_value = false) => this.setParam(action, param, message_number, str_value, pc_style, cc_style, fix_value)}
                                 deleteCallback={(message_number) => this.deleteMessage(message_number)}
                                 moveUpCallback={i === 0 ? null : (message_number) => this.moveMessage(message_number, message_number - 1)}
                                 moveDownCallback={i === (N-1) ? null : (message_number) => this.moveMessage(message_number, message_number + 1)}
                                 clockState={S.global[global_offset.midi_clock]}
                                 baby3={S.isBaby3()}
                                 bool={this.state.refresh_messages} />
                    )}
                </div>}

                {N < 16 &&
                <div className="row aligned">
                    <button type="button" className="btn btn-x-small btn-success" onClick={this.addMessage}>Add message</button>
                    <div className="help-text left-space">You can define up to 16 messages per action.</div>
                </div>}

            </div>
        );
    }
}

export default inject('appState')(observer(ActionEditor));
