import React from "react";
import {inject, observer} from "mobx-react";
import {ACTION} from "../model/constants";
import ExpMessagesSummary from "./ExpMessagesSummary";
import "./Exp.css";

class Exp extends React.Component {

    render() {

        const {button, name} = this.props;

        return (
            <div className={`panel-wrapper button-editor`}>
                <div className="panel-title">
                    <div>{name}</div>
                </div>
                <ExpMessagesSummary button={button} action={ACTION.tap} />
            </div>
        );
    }
}

//FIXME: remove appState if not used.

export default inject('appState')(observer(Exp));
