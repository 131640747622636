import React from "react";
import {inject, observer} from "mobx-react";
import GlobalConfig from "./GlobalConfig";
import Button from "./Button";
import Exp from "./Exp";

class Editor extends React.Component {

    render() {

        // const buttons = [];
        // for (let [button, attr] of Object.entries(this.props.appState.getButtons())) {
        //     buttons.push(attr.exp ? <Exp key={`exp-${button}-${attr.name}`} button={button} name={attr.name} /> : <Button key={`sw-${button}-${attr.name}`} button={button} name={attr.name} />);
        // }

        return (
            <div className="content">
                <GlobalConfig />
                {this.props.appState.getButtons().map(
                    b => b.exp ?
                        <Exp key={`exp-${b.button}-${b.name}`} button={b.button} name={b.name} />  :
                        <Button key={`sw-${b.button}-${b.name}`} button={b.button} name={b.name} />
                )}
            </div>
        );
    }
}

export default inject('appState')(observer(Editor));

