import React, {Fragment} from "react";
import * as Note from "tonal-note";
import {inject, observer} from "mobx-react";
import {
    BATCH_COUNTER_LABEL_BABY,
    BATCH_COUNTER_LABEL_BABY3,
    MESSAGE_TYPE,
    MESSAGE_TYPE_LABEL, MSG_WITH_CHANNEL,
} from "../model/constants";
import {
    offsetChannel,
    offsetController, offsetCounter,
    offsetEndValue,
    offsetMessageType,
    offsetStartValue,
    offsetStep, presetValue
} from "../model/preset";
import {
    CC_RESEND_COUNTER,
    CC_SEND_END,
    CC_STEP, getCCMessageStyle,
    getPCMessageStyle,
    PC_RESEND_COUNTER,
    PC_SEND_END,
    PC_STEP
} from "../model";
import "./ActionMessages.css";

const Message = ({i, preset, isBaby3}) => {

    const msg_type = presetValue(preset, offsetMessageType(i));

    let channel;
    if (MSG_WITH_CHANNEL.includes(msg_type)) {
        channel = <div>Channel {presetValue(preset, offsetChannel(i))}</div>;
    } else {
        channel = <div></div>;
    }

    let s = null;

    const controller = presetValue(preset, offsetController(i));
    const start = presetValue(preset, offsetStartValue(i));
    const end = presetValue(preset, offsetEndValue(i));
    const step = presetValue(preset, offsetStep(i));
    const counter = presetValue(preset, offsetCounter(i)) + 1;
    const step_delay_ms = ((step ? step : 0) + 1) * 5;
    const batch_counter = presetValue(preset, offsetController(i));
    const batch_counter_label = isBaby3 ? BATCH_COUNTER_LABEL_BABY3[batch_counter] : BATCH_COUNTER_LABEL_BABY[batch_counter];

    switch (msg_type) {
        case MESSAGE_TYPE.PC:
            switch (getPCMessageStyle(preset, i)) {
                case PC_RESEND_COUNTER:
                    s = <Fragment>
                        <div className="msg-channel">
                            {channel}
                        </div>
                        <div className="msg-description">
                            {/*<div>Send current counter value when triggered. Use counter {counter}</div>*/}
                            <div>Send counter {counter} value.</div>
                        </div>
                    </Fragment>;
                    break;
                case PC_SEND_END:
                    s = <Fragment>
                        <div className="msg-channel">
                            {channel}
                        </div>
                        <div className="msg-description">
                            <div>Send value {end}.</div>
                        </div>
                    </Fragment>;
                    break;
                case PC_STEP:
                    s = <Fragment>
                        <div className="msg-channel">
                            {channel}
                        </div>
                        <div className="msg-description">
                            {(end >= start) && <div>Count from {start} up to {end} by {step}, update counter {counter} and send value.</div>}
                            {(end < start) && <div>Count from {start} down to {end} by {step}, update counter {counter} and send value.</div>}
                        </div>
                    </Fragment>;
                    break;
                default:
                    console.warn("ActionMessage.Message: invalid pc_style");
                //     s = null;
            }
            break;
        case MESSAGE_TYPE.CC_toggle:
            switch (getCCMessageStyle(preset, i)) {
                case CC_RESEND_COUNTER:
                    s = <Fragment>
                        <div className="msg-channel">
                            {channel}
                        </div>
                        <div className="msg-description">
                            <div>Send CC{controller} with counter {counter} value.</div>
                        </div>
                    </Fragment>;
                    break;
                case CC_SEND_END:
                    s = <Fragment>
                        <div className="msg-channel">
                            {channel}
                        </div>
                        <div className="msg-description">
                            <div>Send CC{controller} value {end}.</div>
                        </div>
                    </Fragment>;
                    break;
                case CC_STEP:
                    s = <Fragment>
                        <div className="msg-channel">
                            {channel}
                        </div>
                        <div className="msg-description">
                            {(end >= start) && <div>Count from {start} up to {end} by {step}, update counter {counter} and send value.</div>}
                            {(end < start) && <div>Count from {start} down to {end} by {step}, update counter {counter} and send value.</div>}
                        </div>
                    </Fragment>;
                    break;
                default:
                    console.warn("ActionMessage.Message: invalid cc_style");
            }
            break;
        case MESSAGE_TYPE.CC_return:
            s = <Fragment>
                <div className="msg-channel">
                    {channel}
                </div>
                <div className="msg-description">
                    <div>Send CC{controller} value {start}, wait {step_delay_ms}ms, send CC{controller} value {end}.</div>
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.note:
            s = <Fragment>
                {/*<div className="msg-type">{MESSAGE_TYPE_LABEL[msg_type]}</div>*/}
                <div className="msg-channel">
                    {channel}
                </div>
                <div className="msg-description">
                    <div>Send NOTE ON {Note.fromMidi(controller)} ({controller}) velocity {start} {end === 0 ? '(will be interpreted as NOTE OFF)' : ''}.</div>
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.note_return:
            s = <Fragment>
                <div className="msg-channel">
                    {channel}
                </div>
                <div className="msg-description">
                    <div>Send NOTE ON {Note.fromMidi(controller)} ({controller}) velocity {start}, wait {step_delay_ms}ms, send NOTE OFF {Note.fromMidi(controller)} ({controller}).</div>
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.set_tempo:
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.start_clock:
        case MESSAGE_TYPE.stop_clock:
        case MESSAGE_TYPE.toggle_clock:
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.batch_count_dec :     // Decrement batch counter
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                    Batch counter {batch_counter_label}, dec by {end}
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.batch_dec_fire :      // Decrement batch and fire action
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                    Batch counter {batch_counter_label}, dec by {end}
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.batch_count_inc :     // Increment batch counter
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                    Batch counter {batch_counter_label}, inc by {start}
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.batch_inc_fire :      // Increment batch counter and fire action
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                    Batch counter {batch_counter_label}, inc by {start}
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.batch_count_set :     // Set batch counter to a number
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                    Batch counter {batch_counter_label}, set to {start}
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.batch_set_fire :      // Set batch to number and fire action
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                    Batch counter {batch_counter_label}, set to {start}
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.no_action:
            s = <Fragment>
                <div className="msg-channel">
                </div>
                <div className="msg-description">
                </div>
            </Fragment>;
            break;
        case MESSAGE_TYPE.strymon_bank:
            s = <Fragment>
                <div className="msg-channel">
                    {channel}
                </div>
                <div className="msg-description">
                    {end === 0 ? 'Send BANK DOWN command' : 'Send BANK UP command'}.
                </div>
            </Fragment>;
            break;
        default:
            s = '';
            break;
    }

    return (
        <Fragment>
            <div className="msg-number">{i+1}:</div>
            <div className="msg-type">{MESSAGE_TYPE_LABEL[msg_type]}</div>
            {s}
        </Fragment>
    );
};

class ActionMessages extends React.Component {

    render() {

        const { button, action } = this.props;
        const S = this.props.appState;
        const N = S.getNumberOfMessages(button, action);
        const isBaby3 = S.isBaby3();

        return (
            <div className="messages-summary">
                {Array.from(Array(N).keys()).map(i =>
                    <Message key={i} i={i} preset={S.preset[button][action]} isBaby3={isBaby3} />
                )}
            </div>
        );
    }
}

export default inject('appState')(observer(ActionMessages));
