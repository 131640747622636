import {hs} from "../utils/hexstring";
import {offsetEndValue, offsetMessageType, offsetStartValue, offsetStep, presetValue} from "./preset";
import {MESSAGE_TYPE, MODEL_BABY1, MODEL_BABY3, MODEL_UNKNOWN} from "./constants";

export const WAIT_BETWEEN_MESSAGES = 200;

export function getNameAndVersion(data) {

    // getNameAndVersion([0xF0, 0x7E, 0x00, 0x06, 0x00, 0x02, 0x17, 0x0B, 0x0B, 0x00, 0x00, 0x00, 0x0B])
    // 0  1  2  3  4  5  6  7  8  9  0  1  2  3  4
    // F0 7E 00 06 02 00 02 17 0B 0B 00 00 00 0B F7

    const v = {
        model: MODEL_UNKNOWN,
        version: ""
    };
    if (data.length !== 15) return v;

    const hex = hs(data);

    if (global.dev) console.log("version", hex);

    if (hex.substring(5*3, 5*3 + 8) !== "00 02 17") return v;
    if (hex.substring(8*3, 8*3 + 5) === "0B 0B") {
        v.model = MODEL_BABY1;
    } else if (hex.substring(8*3, 8*3 + 5) === "0B 03") {
        v.model = MODEL_BABY3;
    } else {
        v.model = MODEL_UNKNOWN;
    }
    v.version = parseInt(hex.substring(10*3, 10*3 + 11).replace(/ /g, ''), 16);
    return v;
}

export const PC_RESEND_COUNTER = 1;     // send counter
export const PC_SEND_END = 2;           // send single value
export const PC_STEP = 3;               // count & send
export const CC_RESEND_COUNTER = 1;
export const CC_SEND_END = 2;
export const CC_STEP = 3;

/**
 * Return the style of PC messages (send counter, count with step, send single value). Return -1 for non-PC messages.
 * @param preset
 * @param message_number
 * @returns {number}
 */
export function getPCMessageStyle(preset, message_number) {

    // const msg_type = parseInt(preset[offsetMessageType(message_number)], 10);
    const msg_type = presetValue(preset, offsetMessageType(message_number));

    if (msg_type !== MESSAGE_TYPE.PC) {
        // if (global.dev) console.warn("getPCMessageStyle: not a PC message", hs(preset), message_number);
        return -1;
    }

    if (global.dev) console.log("getPCMessageStyle", message_number, preset[offsetStartValue(message_number)], preset[offsetEndValue(message_number)], preset[offsetStep(message_number)]);

    if (presetValue(preset, offsetStep(message_number)) === 0) {
        // "send counter"
        // If step == 0 then re-send current counter value when triggered, ignore start and end
        return PC_RESEND_COUNTER;
    } else if (presetValue(preset, offsetStartValue(message_number)) === presetValue(preset, offsetEndValue(message_number))) {
        // "send single value"
        // Else if start == end AND step != 0, send END VALUE
        return PC_SEND_END;
    } else {
        // "count & send"
        // Else check range and count up or down, update assigned counter
        return PC_STEP;
    }

/*
    if (global.dev) console.log("getPCMessageStyle", message_number, preset[offsetStartValue(message_number)], preset[offsetEndValue(message_number)], preset[offsetStep(message_number)]);

    if (parseInt(preset[offsetStep(message_number)], 10) === 0) {
        // If step == 0 then re-send current counter value when triggered, ignore start and end
        return PC_RESEND_COUNTER;
    } else if (parseInt(preset[offsetStartValue(message_number)], 10) === parseInt(preset[offsetEndValue(message_number)], 10)) {
        // Else if start == end AND step != 0, send END VALUE
        return PC_SEND_END;
    } else {
        // Else check range and count up or down, update assigned counter
        return PC_STEP;
    }
*/
}

/**
 * Return the style of CC messages (send counter, count with step, send single value). Return -1 for non-PC messages.
 * @param preset
 * @param message_number
 * @returns {number}
 */
export function getCCMessageStyle(preset, message_number) {

    // const msg_type = parseInt(preset[offsetMessageType(message_number)], 10);
    const msg_type = presetValue(preset, offsetMessageType(message_number));

    if (msg_type !== MESSAGE_TYPE.CC_toggle) {
        // if (global.dev) console.warn("getPCMessageStyle: not a PC message", hs(preset), message_number);
        return -1;
    }

    // if (global.dev) console.log("getPCMessageStyle", message_number, preset[offsetStartValue(message_number)], preset[offsetEndValue(message_number)], preset[offsetStep(message_number)]);

    if (presetValue(preset, offsetStep(message_number)) === 0) {
        // If step == 0 then re-send current counter value when triggered, ignore start and end
        return CC_RESEND_COUNTER;
    } else if (presetValue(preset, offsetStartValue(message_number)) === presetValue(preset, offsetEndValue(message_number))) {
        // Else if start == end AND step != 0, send END VALUE
        return CC_SEND_END;
    } else {
        // Else check range and count up or down, update assigned counter
        return CC_STEP;
    }

/*
    if (parseInt(preset[offsetStep(message_number)], 10) === 0) {
        // If step == 0 then re-send current counter value when triggered, ignore start and end
        return CC_RESEND_COUNTER;
    } else if (parseInt(preset[offsetStartValue(message_number)], 10) === parseInt(preset[offsetEndValue(message_number)], 10)) {
        // Else if start == end AND step != 0, send END VALUE
        return CC_SEND_END;
    } else {
        // Else check range and count up or down, update assigned counter
        return CC_STEP;
    }
*/
}

