import React from "react";
import {inject, observer} from "mobx-react";
import {MIDI_CLOCK_LABEL, MULTIJACK_LABEL, USB_FUNCTION_LABEL} from "../model/constants";
import {global_offset} from "../model/global";
import "./GlobalConfig.css";
// import {getNameAndVersion} from "../model";

class GlobalConfig extends React.Component {

    render() {

        // console.log("print/GlobalConfig.render");

        const S = this.props.appState;
        const G = S.global;

        // const v = getNameAndVersion(S.meta);

        console.log("print/GlobalConfig.render", this.props.appState.model, S.isBaby1(), S.isBaby3());

        const LED1States = this.props.appState.getLEDStateOptions(1);
        const LED2States = S.isBaby1() ? null : this.props.appState.getLEDStateOptions(2);
        const LED3States = S.isBaby1() ? null : this.props.appState.getLEDStateOptions(3);

        return (
            <div>
                <div className="panel-title">
                    Global settings
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td className="print-global-label">
                                {S.isBaby1() && "MultiJack"}
                                {S.isBaby3() && "MultiJack left (black)"}
                            </td>
                            <td className="print-global-value">{MULTIJACK_LABEL[G[global_offset.multijack]]}</td>
                        </tr>
                        {S.isBaby3() &&
                        <tr>
                            <td className="print-global-label">
                                MultiJack right (red):
                            </td>
                            <td className="print-global-value">{MULTIJACK_LABEL[G[global_offset.multijack2]]}</td>
                        </tr>}
                        <tr>
                            <td className="print-global-label">MIDI input channel</td>
                            <td className="print-global-value">{G[global_offset.midi_in_channel]}</td>
                        </tr>
                        <tr>
                            <td className="print-global-label">MIDI thru</td>
                            <td className="print-global-value">
                                {S.getMidiThru('din') && <span>DIN&nbsp;</span>}
                                {S.getMidiThru('usb') && <span>USB&nbsp;</span>}
                                {S.getMidiThru('multijack') && <span>Multijack</span>}
                            </td>
                        </tr>
                        <tr>
                            <td className="print-global-label">MIDI clock</td>
                            <td className="print-global-value">
                                {MIDI_CLOCK_LABEL[G[global_offset.midi_clock]]}
                                {/*{G[global_offset.midi_clock] ? "ON" : "OFF"}*/}
                            </td>
                        </tr>
                        <tr>
                            <td className="print-global-label">USB function</td>
                            <td className="print-global-value">
                                {USB_FUNCTION_LABEL[G[global_offset.usb]]}
                            </td>
                        </tr>
                        <tr>
                            <td className="print-global-label">
                                {S.isBaby1() && "LED brightness"}
                                {S.isBaby3() && "LEDs brightness"}
                            </td>
                            <td className="print-global-value">{G[global_offset.led_brightness]}</td>
                        </tr>
                        <tr>
                            <td className="print-global-label">
                                {S.isBaby1() && "LED off state"}
                                {S.isBaby3() && "LED 1 off state"}
                            </td>
                            <td className="print-global-value">{LED1States[G[global_offset.led_state]]}</td>
                        </tr>
                        {S.isBaby3() &&
                        <tr>
                            <td className="print-global-label">LED 2 off state</td>
                            <td className="print-global-value">{LED2States[G[global_offset.led2_state]]}</td>
                        </tr>}
                        {S.isBaby3() &&
                        <tr>
                            <td className="print-global-label">LED 3 off state</td>
                            <td className="print-global-value">{LED3States[G[global_offset.led3_state]]}</td>
                        </tr>}
                    </tbody>
                </table>
{/*
                <div>Firmware version:</div>
                <div className="values-row">
                    <div>
                        {v.model}
                        {v.version ? ` v.${v.version}` : ''}
                    </div>
                </div>
*/}
            </div>
        );
    }
}

// https://github.com/mobxjs/mobx-react/issues/250
export default inject('appState')(observer(GlobalConfig));
