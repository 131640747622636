import React from "react";
import {inject, observer} from "mobx-react";
import {loadPreferences, savePreferences} from "../utils/preferences";
import "./Information.css";

class Information extends React.Component {

    state = {
        dismissed: false,
        not_again: false
    };

    toggleNotAgain = () => {
        const b = this.state.not_again;
        this.setState({not_again: !b});
    };

    dismiss = () => {
        this.setState({dismissed: true});
        savePreferences({info_not_again: this.state.not_again});
    };

    componentDidMount(){
        const s = loadPreferences();
        if (s.info_not_again === undefined) {
            // undefined because not in pref yet
            this.setState({dismissed: false});
        } else {
            this.setState({dismissed: s.info_not_again});
        }
        //FIXME: use !! operator
    }

    render() {
        if (this.state.dismissed || !this.props.appState.connected) return null;
        return (
            <div className="information">
                {/*<div className="sub-info">*/}
                {/*    This information only appears when a MIDI Baby is connected<br />*/}
                {/*    and its configuration has not been read yet.*/}
                {/*</div>*/}
                <div className="important">IMPORTANT</div>
                <div className="important">The editor does not currently display the MIDI Baby configuration.</div>
                <div>You have two options:</div>
                <ul>
                    <li>
                        <strong>Overwrite the MIDI Baby configuration:</strong>
                        <ol>
                            <li>Create your configuration with the editor.</li>
                            <li>Click on the <u>Write device</u> button to save your changes into the MIDI Baby.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Update the existing MIDI Baby configuration:</strong>
                        <ol>
                            <li>Click on the <u>Read device</u> button to read the MIDI Baby configuration.</li>
                            <li>Edit the configuration.</li>
                            <li>Click on the <u>Write device</u> or <u>Save</u> button to save your changes into the MIDI Baby.</li>
                        </ol>
                    </li>
                </ul>
                <div>Saving your changes:</div>
                <ul>
                    <li>
                        The <u>Write device</u> button will always save everything at once (global config,  buttons, ext footswitch, exp pedal).<br />
                        This will completely overwrite the MIDI Baby configuration with the one in the editor.
                    </li>
                    <li>
                        The <u>Save button</u> that is displayed when you have modified a subset of the configuration (eg. only one button) will only save these changes.<br />
                        This is faster if you only change a few parameters.
                    </li>
                </ul>
                <div className="information-actions">
                    <label onClick={(e) => e.stopPropagation()}>
                        <input type="checkbox" checked={this.state.not_again} onChange={this.toggleNotAgain} />Do not show again
                    </label>
                    <button className="btn btn-secondary" onClick={this.dismiss}>Dismiss</button>
                </div>
            </div>
        );
    }

}

export default inject('appState')(observer(Information));

