import {computed, decorate, observable} from 'mobx';
import {getDefaultGlobalSysex, global_offset, midi_thru_mask} from "../model/global";
import {getPresetsStruct, offset, preset_offset} from "../model/preset";
import {
    BABY1_BUTTONS,
    BABY3_BUTTONS,
    button_offset,
    getButtonsDirtyStruct,
    getButtonsStruct} from "../model/button";
import {
    ACTION,
    CMD_LOAD_BUTTON_CONFIG,
    CMD_LOAD_GLOBAL_CONFIG, CMD_LOAD_PRESET, MODEL_BABY1, MODEL_BABY3, MODEL_UNKNOWN, MULTIJACK,
    SYSEX_END,
    SYSEX_SIGNATURE,
    SYSEX_START
} from "../model/constants";
import {byteArray2stringArray, stringArray2byteArray} from "../utils/utils";
import {parseSysexDump} from "../model/sysex";
import {deviceCheck} from "../utils/midi";

export const DIRECTION_READ = 'Reading';    // value must also match css class used like .midi-progress.reading
export const DIRECTION_WRITE = 'Writing';   // value must also match css class used like .midi-progress.writing

// export const WHAT_VERSION = 'version';
// export const WHAT_GLOBAL = 'global';
// export const WHAT_PRESET = 'preset';

class AppState {

    // foo = "bar";

    // meta = [];  // will hold firmware version sysex
    version = 0;
    model = MODEL_UNKNOWN;

    baby1_check_version = '';     // from external file
    baby1_check_text = '';        // from external file
    baby3_check_version = '';     // from external file
    baby3_check_text = '';        // from external file

    global = getDefaultGlobalSysex();   //new Array(global_length).fill(0);
    global_dirty = false;

    // button = getDefaultButtonSysex(0);   //new Array(button_length).fill(0);
    button = getButtonsStruct();

    // button = {
    //     [BUTTON.footswitch]: getDefaultButtonSysex(BUTTON.footswitch),
    //     [BUTTON.tip]: getDefaultButtonSysex(BUTTON.tip),
    //     [BUTTON.ring]: getDefaultButtonSysex(BUTTON.ring),
    // };

    button_dirty = getButtonsDirtyStruct();

    // button_dirty = {
    //     [BUTTON.footswitch]: false,
    //     [BUTTON.tip]: false,
    //     [BUTTON.ring]: false,
    // };

    preset = getPresetsStruct();

    //TODO: add method to read and update preset values with automatic type convertion (int-str)

/*
    preset = {
        [BUTTON.footswitch]: {
            [ACTION.tap]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.footswitch, ACTION.tap)),
            [ACTION.hold]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.footswitch, ACTION.hold)),
            [ACTION.long_hold]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.footswitch, ACTION.long_hold)),
            dirty: false
        },
        [BUTTON.tip]: {
            [ACTION.tap]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.tip, ACTION.tap)),
            [ACTION.hold]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.tip, ACTION.hold)),
            [ACTION.long_hold]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.tip, ACTION.long_hold)),
            dirty: false
        },
        [BUTTON.ring]: {
            [ACTION.tap]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.ring, ACTION.tap)),
            [ACTION.hold]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.ring, ACTION.hold)),
            [ACTION.long_hold]: byteArray2stringArray(getDefaultPresetSysex(BUTTON.ring, ACTION.long_hold)),
            dirty: false
        }
    };
*/

    midi = {
        input: null,        // midi port ID
        output: null        // midi port ID
    };

    // for progress indicator:
    //TODO: idea: store this kind of data in the global window scope?
    // used to keep track of READ progress
    expected = [];  // expected messages; array of {data, consume}  If consume=true, then handle the message, otherwise ignore it
                    // data contains the byte (from very first byte) to compare. This can be only the first byte or a full message.

    // for progress indicator
    direction = ''; // const like DIRECTION_READ
    what = ''; // to show when reading version or reading preset ...

    number_expected = 0;    // used to keep track of WRITE progress
    write_progress = 0;     // INT 0..<number_expected>

    device_ok = false;

    get connected() {
        return !!(this.midi.input && this.midi.output);
    }

    isBaby1() {
        return this.model === MODEL_BABY1;
    }

    isBaby3() {
        return this.model === MODEL_BABY3;
    }

    isDirty() {
        if (this.isGlobalDirty()) return true;
        const m = this.getNumberOfButtons();
        for (let button=0; button < m; button++) {
            if (this.isButtonDirty(button)) return true;
        }
        return false;
    }

    setVersion(version) {
        this.version = version;
    }

    setModel(model) {
        this.model = model;
    }

    getNumberOfButtons() {
        return this.isBaby3() ? BABY3_BUTTONS : BABY1_BUTTONS;
    }

    /**
     * Returns an array. The array order is the order to be displayed.
     *
     * BABY 1:
     *      0	Main FS
     *      1	MultiJack Tip	Expression Pedal
     *      2	MultiJack Ring
     *
     * BABY 3:
     *      0	Right FS
     *      1	Center FS
     *      2	Left FS
     *      3	MultiJack Tip	Expression Pedal    LEFT
     *      4	MultiJack Ring
     *      5	MultiJack 2 Tip	Expression Pedal    RIGHT
     *      6	MultiJack 2 Ring
     * jack 1 is black, left side
     * jack 2 is red, right side
     */
    getButtons() {
        const buttons = [];
        if (this.isBaby3()) {

            buttons.push(
                {button: 2, name: "Left footswitch", exp: false},
                {button: 1, name: "Center footswitch", exp: false},
                {button: 0, name: "Right footswitch", exp: false}
            );

            // let i = 3;

            // LEFT (BLACK) jack
            if (this.global[global_offset.multijack] === MULTIJACK.fs) {
                buttons.push(
                    {button: 3, name: "Left External footswitch (black) - Tip", exp: false},    // TIP
                    {button: 4, name: "Left External footswitch (black) - Ring", exp: false}    // RING
                );
            } else if (this.global[global_offset.multijack] === MULTIJACK.exp) {
                buttons.push(
                    {button: 3, name: "Left Expression pedal (black)", exp: true}   // TIP
                );
            }

            // RIGHT (RED) jack
            if (this.global[global_offset.multijack2] === MULTIJACK.fs) {
                buttons.push(
                    {button: 5, name: "Right External footswitch (red) - Tip", exp: false},     // TIP
                    {button: 6, name: "Right External footswitch (red) - Ring", exp: false}     // RING
                );
            } else if (this.global[global_offset.multijack2] === MULTIJACK.exp) {
                buttons.push(
                    {button: 5, name: "Right Expression pedal (red)", exp: true}    // TIP
                );
            }

            // console.log("getButtons", b);
        } else {
            if (this.global[global_offset.multijack] === MULTIJACK.fs) {
                buttons.push(
                    {button: 0, name: "Main footswitch", exp: false},
                    {button: 1, name: "External footswitch - Tip", exp: false},
                    {button: 2, name: "External footswitch - Ring", exp: false}
                );
            } else {
                buttons.push(
                    {button: 0, name: "Main footswitch", exp: false},
                    {button: 1, name: "Expression pedal", exp: true}
                );
            }
        }
        return buttons;
/*
        if (this.isBaby3()) {
            let b = {
                0: {name: "Right footswitch", exp: false},
                1: {name: "Center footswitch", exp: false},
                2: {name: "Left footswitch", exp: false}
            };
            let i = 3;
            if (this.global[global_offset.multijack] === MULTIJACK.fs) {
                b[i++] = {name: "External footswitch left (black) - Tip", exp: false};
                b[i++] = {name: "External footswitch left (black) - Ring", exp: false};
            } else if (this.global[global_offset.multijack] === MULTIJACK.exp) {
                b[i++] = {name: "Expression pedal left (black)", exp: true};
            }
            if (this.global[global_offset.multijack2] === MULTIJACK.fs) {
                b[i++] = {name: "External footswitch right (red) - Tip", exp: false};
                b[i] = {name: "External footswitch right (red) - Ring", exp: false};
            } else if (this.global[global_offset.multijack2] === MULTIJACK.exp) {
                b[i] = {name: "Expression pedal right (red)", exp: true};
            }
            // console.log("getButtons", b);
            return b;
        } else {
            if (this.global[global_offset.multijack] === MULTIJACK.fs) {
                return {
                    0: {name: "Main footswitch", exp: false},
                    1: {name: "External footswitch - Tip", exp: false},
                    2: {name: "External footswitch - Ring", exp: false}
                };
            } else {
                return {
                    0: {name: "Main footswitch", exp: false},
                    1: {name: "Expression pedal", exp: true}
                };
            }
        }
*/
    }

    getLEDStateOptions() {

        //FIXME: declare constants for the state labels

        let options;
        if (this.isBaby3()) {
            options = {
                0: "Blink during use only",
                1: "Follow Right footswitch Press",
                2: "Follow Right footswitch Hold",
                3: "Follow Right footswitch Long Hold",
                4: "Follow Center footswitch Press",
                5: "Follow Center footswitch Hold",
                6: "Follow Center footswitch Long Hold",
                7: "Follow Left footswitch Press",
                8: "Follow Left footswitch Hold",
                9: "Follow Left footswitch Long Hold"
            };
            let i = 9;
            if (this.global[global_offset.multijack] === MULTIJACK.fs) {
                options[++i] = "Follow Left Multijack Tip Press";
                options[++i] = "Follow Left Multijack Tip Hold";
                options[++i] = "Follow Left Multijack Tip Long Hold";
                options[++i] = "Follow Left Multijack Ring Press";
                options[++i] = "Follow Left Multijack Ring Hold";
                options[++i] = "Follow Left Multijack Ring Long Hold";
            }
            if (this.global[global_offset.multijack2] === MULTIJACK.fs) {
                options[++i] = "Follow Right Multijack Tip Press";
                options[++i] = "Follow Right Multijack Tip Hold";
                options[++i] = "Follow Right Multijack Tip Long Hold";
                options[++i] = "Follow Right Multijack Ring Press";
                options[++i] = "Follow Right Multijack Ring Hold";
                options[++i] = "Follow Right Multijack Ring Long Hold";
            }
        } else {
            options = {
                0: "Blink during use only",
                1: "Follow Main footswitch Press",
                2: "Follow Main footswitch Hold",
                3: "Follow Main footswitch Long Hold"
            };
            let i = 3;
            if (this.global[global_offset.multijack] === MULTIJACK.fs) {
                options[++i] = "Follow Multijack Tip Press";
                options[++i] = "Follow Multijack Tip Hold";
                options[++i] = "Follow Multijack Tip Long Hold";
                options[++i] = "Follow Multijack Ring Press";
                options[++i] = "Follow Multijack Ring Hold";
                options[++i] = "Follow Multijack Ring Long Hold";
            }
        }
        return options;
    }

    setMidiInput(port_id) {
        const changed = port_id !== this.midi.input;   // to force checkDevice with we replace an output by another output
        this.midi.input = port_id;
        if (changed) {
            this.device_ok = false;
        }
        if (port_id) {
            this.checkDevice();
        }
    }

    setMidiOutput(port_id) {
        const changed = port_id !== this.midi.output;   // to force checkDevice with we replace an output by another output
        this.midi.output = port_id;
        if (changed) {
            this.device_ok = false;
        }
        if (port_id) {
            this.checkDevice();
        }
    }

    // get isPresetDirty() {
    //     return this.preset[BUTTON.footswitch].dirty || this.preset[BUTTON.tip].dirty || this.preset[BUTTON.ring].dirty;
    // }

    setGlobalDirty() {
        this.global_dirty = true;
    }

    setGlobalClean() {
        this.global_dirty = false;
    }

    isGlobalDirty() {
        return this.global_dirty;
    }

    setButtonDirty(button) {
        this.button_dirty[button] = true;
    }

    setButtonClean(button) {
        this.button_dirty[button] = false;
    }

    isButtonDirty(button) {
        return this.button_dirty[button];
    }

    setPresetDirty(button) {
        this.preset[button].dirty = true;
    }

    setPresetClean(button) {
        this.preset[button].dirty = false;
    }

    isPresetDirty(button) {
        return this.preset[button].dirty;
    }

    reset() {
        this.global = getDefaultGlobalSysex();   //new Array(global_length).fill(0);
        this.button = getButtonsStruct();
        this.button_dirty = getButtonsDirtyStruct();
        this.preset = getPresetsStruct();
        // this.button = {
        //     [BUTTON.footswitch]: getDefaultButtonSysex(BUTTON.footswitch),
        //     [BUTTON.tip]: getDefaultButtonSysex(BUTTON.tip),
        //     [BUTTON.ring]: getDefaultButtonSysex(BUTTON.ring)
        // };
    }

    /**
     * Must be called first each time the user trigger a midi message
     */
    resetExpected(number_expected=0) {
        this.number_expected = number_expected;
        this.write_progress = 0;
        this.expected = [];
        this.what = '';
    }

    // updateExpected(number_expected=0) {
    //     this.number_expected = number_expected;
    // }

    addExpected(data, consume=false) {
        this.expected.push({data, consume, done: false});
        //TODO: specify (add) expected length ?
    }

    /**
     *
     * @param received
     * @returns {null|boolean} null if we can ignore the expected flag; otherwise true|false to indicates if we must consume or ignore the message
     */
    isExpected(received) {

        if (this.expected.length === 0) return null;    // null means we can ignore the expected flag
        for (let i=0; i<this.expected.length; i++) {

            // if (global.dev) console.log(`expected[${i}]...`);

            if (this.expected[i].done) {
                // if (global.dev) console.log(`expected[${i}] is done, ignore`);
                continue;
            }
            const expected = this.expected[i].data;

            // if (global.dev) console.log(`expected[${i}].data`, hs(expected));

            if (expected.length <= received.length) {
                // if (global.dev) console.log(`expected[${i}]...bytes check`, hs(received), '-', hs(expected));
                let done = true;
                for (let k=0; k<expected.length; k++) {
                    if (received[k] !== expected[k]) {
                        done = false;
                        break;
                    }
                }
                if (!done) continue;

                this.expected[i].done = true;

                return this.expected[i].consume;    // return the flag indicating if we must consume of ignore the message
            }
        }
        return null;    // null means we can ignore the expected flag
    }

    getNumberOfMessages(button, action) {   // !!! the number of messages is in the button sysex, not in the preset sysex
        switch(action) {
            case ACTION.tap: return this.button[button][button_offset.msgs_tap];
            case ACTION.hold: return this.button[button][button_offset.msgs_hold];
            case ACTION.long_hold: return this.button[button][button_offset.msgs_long_hold];
            default: console.error(`getNumberOfMessages: invalid action: ${action}`);
        }
        return 0;   // TODO: throw error
    }

    decNumberOfMessages(button, action) {
        //TODO: check boundaries
        switch(action) {
            case ACTION.tap: this.button[button][button_offset.msgs_tap]--; return;
            case ACTION.hold: this.button[button][button_offset.msgs_hold]--; return;
            case ACTION.long_hold: this.button[button][button_offset.msgs_long_hold]--; return;
            default: console.error(`getNumberOfMessages: invalid action: ${action}`);
        }
    }

    incNumberOfMessages(button, action) {
        // if (global.dev) console.log(`incNumberOfMessages(${action})`, this.button[button][button_offset.msgs_tap]);
        //TODO: check boundaries
        switch(action) {
            case ACTION.tap: this.button[button][button_offset.msgs_tap]++; return;
            case ACTION.hold: this.button[button][button_offset.msgs_hold]++; return;
            case ACTION.long_hold: this.button[button][button_offset.msgs_long_hold]++; return;
            default: console.error(`getNumberOfMessages: invalid action: ${action}`);
        }
        return 0;
    }

    deleteMessage(button, action, index) {
        this.decNumberOfMessages(button, action);
        const N = this.getNumberOfMessages(button, action);
        if (index > N) {
            // we deleted the last message
        } else {
            // we deleted a message in the middle

            let bytes = this.presetBytes(button, action);

            for (let i=index+1; i<=N; i++) {
                // move i to i-1
                bytes[offset('messagetype', i-1)] = bytes[offset('messagetype', i)];
                bytes[offset('controller', i-1)] = bytes[offset('controller', i)];
                bytes[offset('channel', i-1)] = bytes[offset('channel', i)];    // channel value is 0..16 displayed as 0..16
                bytes[offset('startvalue', i-1)] = bytes[offset('startvalue', i)];
                bytes[offset('endvalue', i-1)] = bytes[offset('endvalue', i)];
                bytes[offset('step', i-1)] = bytes[offset('step', i)];
                bytes[offset('counter', i-1)] = bytes[offset('counter', i)];
            }
            this.preset[button][action] = byteArray2stringArray(bytes);
        }
    }

    moveMessage(button, action, index, new_index) {
        const bytes = this.presetBytes(button, action);
        ['messagetype', 'controller', 'channel', 'startvalue', 'endvalue', 'step', 'counter'].forEach(
            function (prop) {
                [bytes[offset(prop, new_index)], bytes[offset(prop, index)]] = [bytes[offset(prop, index)], bytes[offset(prop, new_index)]]
                // let tmp = bytes[offset(prop, new_index)];
                // bytes[offset(prop, new_index)] = bytes[offset(prop, index)];
                // bytes[offset(prop, index)] = tmp;
            }
        );
        this.preset[button][action] = byteArray2stringArray(bytes);
    }

    getDelayBetweenMessages(button, action) {
        switch(action) {
            case ACTION.tap: return this.button[button][button_offset.delay_tap];
            case ACTION.hold: return this.button[button][button_offset.delay_hold];
            case ACTION.long_hold: return this.button[button][button_offset.delay_long_hold];
            default: console.error(`getDelayBetweenMessages: invalid action: ${action}`);
        }
        return 0;   // TODO: throw error
    }

    getColor(button, action) {
        switch(action) {
            case ACTION.tap: return this.button[button][button_offset.color_tap];
            case ACTION.hold: return this.button[button][button_offset.color_hold];
            case ACTION.long_hold: return this.button[button][button_offset.color_long_hold];
            default: console.error(`getColor: invalid action: ${action}`);
        }
        return 0;   // TODO: throw error
    }

    presetBytes(button, action) {
        return stringArray2byteArray(this.preset[button][action]);
    }

    /**
     * Returns true if midi thru is enabled for the output specified
     * @param output 'usb', 'DIN', 'multijack'
     */
    getMidiThru(output) {
        return (this.global[global_offset.midi_thru] & midi_thru_mask[output]) > 0;
    }

    toggleMidiThru(output) {
        if (global.dev) console.log("toggleMidiThru", this.global[global_offset.midi_thru]);
        let v = this.global[global_offset.midi_thru];
        // noinspection JSValidateTypes
        this.global[global_offset.midi_thru] = v ^ midi_thru_mask[output];
        if (global.dev) console.log("toggleMidiThru", this.global[global_offset.midi_thru]);
    }

    getSysexDump() {

        const nb_buttons = this.getNumberOfButtons();

        // 22 + 3*22 + 3*3*136 = 22 + 66 + 1224 = 1312
        const a = new Uint8Array(
            this.global.length + 5 +                                // GLOBAL
            (this.button[0].length + 5) * nb_buttons +                              // Main footswitch + External TIP + External RING
            (this.presetBytes(0, 0).length + 5) * nb_buttons * 3);    // 3 presets per button

        let k = 0;
        a[k] = SYSEX_START;
        k++;
        a.set(SYSEX_SIGNATURE, 1);
        k += 3;
        a.set(Uint8Array.from(this.global), k);
        k += this.global.length;
        a[k] = SYSEX_END;
        k++;

        for (let button = 0; button < nb_buttons; button++) {

            a[k] = SYSEX_START;
            k++;
            a.set(SYSEX_SIGNATURE, k);
            k += 3;

            a.set(Uint8Array.from(this.button[button]), k);
            k += this.button[button].length;
            a[k] = SYSEX_END;
            k++;

            for (let action = 0; action < 3; action++) {

                let preset = this.presetBytes(button, action);
                a[k] = SYSEX_START;
                k++;
                a.set(SYSEX_SIGNATURE, k);
                k += 3;
                a.set(Uint8Array.from(preset), k);
                k += preset.length;
                a[k] = SYSEX_END;
                k++;

            }
        }

        // if (global.dev) console.log(`getSysexDump: ${a.length} bytes`, hs(a));

        return a;
    }

    importSysexDump(bytes, fromFile=false) {

        if (global.dev) console.log("importSysexDump", fromFile);

        const msgs = parseSysexDump(bytes);
        if (msgs) {
            msgs.map(m => {
                const button = m.data[button_offset.button_number];
                // if (global.dev) console.log(`importSysexDump: command is ${h(m.cmd)}`);
                switch (m.cmd) {
                    case CMD_LOAD_GLOBAL_CONFIG:
                        if (global.dev) console.log("importSysexDump: import global");
                        this.global = Array.from(m.data);  // Array.from() needed for mobx observation
                        this.global_dirty = fromFile;
                        break;

                    case CMD_LOAD_BUTTON_CONFIG:
                        if (global.dev) console.log(`importSysexDump: import button ${button}`);
                        this.button[button] = Array.from(m.data);  // Array.from() needed for mobx observation
                        this.button_dirty[button] = fromFile;
                        break;

                    case CMD_LOAD_PRESET: {
                        let action = m.data[preset_offset.preset_action];
                        if (global.dev) console.log(`importSysexDump: import preset button ${button} action ${action}`);
                        this.preset[button][action] = byteArray2stringArray(Array.from(m.data));   // !!!HACK!!! Array.from() needed in roder to avoid having a UInt8Array which is not observed by mobx
                        this.preset[button].dirty = fromFile;
                        break;
                    }
                    default:
                        console.warn(`importSysexDump: invalid m.cmd: ${m.cmd}`);
                }
                return null;
            });
        }
    }


    checkDevice() {
        if (global.dev) console.log("checkDevice()");
        if (this.connected) {
            // noinspection JSIgnoredPromiseFromCall
            deviceCheck();
        } else {
            if (global.dev) console.log("checkDevice: not yet connected");
        }
    }

}

// https://mobx.js.org/best/decorators.html
decorate(AppState, {
    model: observable,
    version: observable,
    baby1_check_version: observable,
    baby1_check_text: observable,
    baby3_check_version: observable,
    baby3_check_text: observable,
    global: observable,
    button: observable,
    preset: observable,
    midi: observable,
    expected: observable,
    direction: observable,
    what: observable,
    write_progress: observable,
    global_dirty: observable,
    button_dirty: observable,
    device_ok: observable,
    connected: computed
});

export const appState = new AppState();
