// noinspection ES6CheckImport
import {DISASTER_AREA_DESIGNS_LLC} from "midi-manufacturers";
import {
    CMD_LOAD_BUTTON_CONFIG,
    CMD_LOAD_GLOBAL_CONFIG,
    CMD_LOAD_PRESET,
    SYSEX_END, SYSEX_START
} from "./constants";
import {h, hs} from "../utils/hexstring";

/**
 *
 * @param data Uint8Array
 */
export function isSysexData(data) {
    if (data[0] !== SYSEX_START) return false;
    // noinspection RedundantIfStatementJS
    if (data[data.byteLength - 1] !== SYSEX_END) return false;
    return true;
}

function parseGlobalConfig(data) {
    // In this version, we do not parse, we just return the data bytes.
    return {
        cmd: CMD_LOAD_GLOBAL_CONFIG,
        data
    };
}

function parseButtonConfig(data) {
    // In this version, we do not parse, we just return the data bytes.
    return {
        cmd: CMD_LOAD_BUTTON_CONFIG,
        data
    };
}

function parsePreset(data) {
    // In this version, we do not parse, we just return the data bytes.
    // if (global.dev) console.log("parsePreset", data.length, hs(data));
    return {
        cmd: CMD_LOAD_PRESET,
        data
    };
}

// // offsets from start of sysex data, right after SYSEX_START
// const CMD = 4;

export function parseSysexMessage(data) {

    // if (global.dev) console.log("parseSysexMessage", hs(data));

    let cmd = data[0];

    switch (cmd) {
        case CMD_LOAD_GLOBAL_CONFIG:
            return parseGlobalConfig(data);
            // break;
        case CMD_LOAD_BUTTON_CONFIG:
            return parseButtonConfig(data);
            // break;
        case CMD_LOAD_PRESET:
            return parsePreset(data);
            // break;
        // case CMD_REQUEST_BUTTON_CONFIG:
        //     return parseRequestButton(data);
        //     break;
        // case CMD_REQUEST_GLOBAL_CONFIG:
        //     return parseRequestGlobal(data);
        //     break;
        // case CMD_REQUEST_PRESET:
        //     return parseRequestPreset(data);
        //     break;
        // case CMD_DISABLE_CLOCK:
        //     return parse(data);
        //     break;
        default:
            console.warn(`parseSysexMessage: invalid command: ${cmd}`);
            return null;
    }

}

/**
 * Parse a sysex dump. A dump can contains multiple sysex messages.
 * Return a array of presets.
 * @param data ArrayBuffer
 */
export function parseSysexDump(data) {

    if (data === null) return [];

    const sysex = [];

    let i = 0;
    let cont = true;
    while (cont) {

        i = data.indexOf(SYSEX_START, i);
        if (i < 0) break;

        i++;

        let k = data.indexOf(SYSEX_END, i);

        let manufacturer_id = (Array.from(data.slice(i, i+3)).map(n => h(n))).join(" ");    // Array.from() is necessary to get a non-typed array
        if (manufacturer_id !== DISASTER_AREA_DESIGNS_LLC) {
            if (global.dev) console.log("parseSysexDump: not a Disaster Area sysex", hs(data));
            return null;
        }

        const m = parseSysexMessage(data.slice(i+3, k));  // data.slice(i, k) are the data between SYSEX_START and SYSEX_END;
        if (m) {
            sysex.push(m);
        }

    }

    return sysex;
}
