import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import {readFiles} from "../utils/utils";
import {DIRECTION_READ, DIRECTION_WRITE} from "../state/State";
import "./Header.css";
import {hsc} from "../utils/hexstring";
import Popup from "reactjs-popup";
import {loadPreferences, savePreferences} from "../utils/preferences";
import {readDevice, writeDevice} from "../utils/midi";


const DEFAULT_THEME = 'dark';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.inputOpenFileRef = React.createRef();
        this.state = { theme: DEFAULT_THEME };
    }

    selectTheme = (theme) => {
        this.setState({theme});
        savePreferences({theme});
    };

    setThemeLight = () => {
        this.selectTheme("light");
    };

    setThemeDark = () => {
        this.selectTheme("dark");
    };

    setThemeDarkest = () => {
        this.selectTheme("darkest");
    };

    componentDidMount(){
        const s = loadPreferences();
        this.setState(
            {
                theme: s.theme || DEFAULT_THEME
            }
        );
    }

    notImplemented = () => {
        window.alert("Sorry, this feature is not yet implemented.");
    };

    exportAsFile = () => {

        const bytes = this.props.appState.getSysexDump();

        let url = window.URL.createObjectURL(new Blob([bytes], {type: "application/octet-stream"}));

        let filename = this.props.appState.isBaby3() ? "midi-baby3" : "midi-baby";

        let now = new Date();
        let timestamp =
            now.getUTCFullYear() + "-" +
            ("0" + (now.getUTCMonth() + 1)).slice(-2) + "-" +
            ("0" + now.getUTCDate()).slice(-2) + "-" +
            ("0" + now.getUTCHours()).slice(-2) + "" +
            ("0" + now.getUTCMinutes()).slice(-2) + "" +
            ("0" + now.getUTCSeconds()).slice(-2);
        filename += '.' + timestamp;

        let shadowlink = document.createElement("a");
        shadowlink.download = filename + ".syx";
        shadowlink.style.display = "none";
        shadowlink.href = url;

        document.body.appendChild(shadowlink);
        shadowlink.click();
        document.body.removeChild(shadowlink);

        setTimeout(function() {
            return window.URL.revokeObjectURL(url);
        }, 1000);

    };

    /**
     *
     * @param files
     * @returns {Promise<void>}
     */
    async importFiles(files) {
        const bytes = await readFiles(files);
        // if (global.dev) console.log("readFiles: bytes read", hs(bytes));
        this.props.appState.importSysexDump(bytes, true);
    }

    onChangeFile = (e) => {
        if (global.dev) console.log("onChangeFile");
        let file = e.target.files[0];
        // noinspection JSIgnoredPromiseFromCall
        this.importFiles([file]);
    };

    importFile = () => {
        if (global.dev) console.log("importFile");
        this.inputOpenFileRef.current.click()
    };

    print = () => {
        let url = `index.html?print=1&model=${this.props.appState.model.toString(16)}&sysex=` + hsc(this.props.appState.getSysexDump());
        window.open(url, "_blank", "width=800,height=600,top=200,left=100,location,resizable,scrollbars,status");
    };

    help = ()  => {
        let url = "index.html?help";
        window.open(url, "_blank", "width=800,height=600,top=200,left=100,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no");
    };

    midi = ()  => {
        let url = "index.html?midi=1";
        window.open(url, "_blank", "width=800,height=600,top=200,left=100,resizable,scrollbars,status");
    };

    render() {

        const { theme } = this.state;

        document.documentElement.setAttribute('data-theme', theme);

        const S = this.props.appState;

        let expected = 0;
        let done = 0;   // number of messages done so far (READING)
        // let progress = '';
        let all_done = false;
        if (S.direction === DIRECTION_READ) {
            expected = S.expected.length;
            done = S.expected.reduce((a, v) => a + (v.done ? 1 : 0), 0);
            // progress = S.number_expected === 0 ? '' : `${Math.round(done / S.number_expected * 100)}%`;
            // progress = S.number_expected === 0 ? '' : `${done}/${S.number_expected}`;
            all_done = done && (done >= S.number_expected);  // >= instead of ===, so this leave the possibility to consider it's all done after X messages
        }
        if (S.direction === DIRECTION_WRITE) {
            expected = S.number_expected;
            // progress = S.number_expected === 0 ? '' : `${Math.round(S.write_progress / S.number_expected * 100)}%`;
            // progress = S.number_expected === 0 ? '' : `${S.write_progress}/${S.number_expected}`;
            all_done = S.write_progress >= S.number_expected;    // >= instead of ===, so this leave the possibility to consider it's all done after X messages
        }

        return (
            <Fragment>
                <div className="pre-header">
                    <button onClick={this.setThemeLight} title="use the light theme">Light</button>
                    <button onClick={this.setThemeDark} title="use the dark theme">Dark</button>
                    <button onClick={this.setThemeDarkest} title="use the darkest theme">Darker</button>
                    <div className="spacer"></div>
                    <a href="https://www.disasterareaamps.com/" target="_blank" rel="noopener noreferrer">Disaster Area Designs</a>
                </div>
                <div className="header">

                    <button type="button" className="btn btn-large btn-warning"
                            disabled={!this.props.appState.connected}
                            onClick={readDevice}
                            title="Read the full configuration of the MIDI BABY.">Read device</button>

                    <button type="button" className="btn btn-large btn-danger"
                            disabled={!this.props.appState.connected}
                            onClick={writeDevice}
                            title="Save everything (global, buttons, actions) in the MIDI BABY.">Write device</button>

                    <input ref={this.inputOpenFileRef} type="file" style={{display:"none"}}  onChange={this.onChangeFile} />
                    <button type="button" className="btn btn-large btn-secondary"
                            onClick={this.importFile}
                            title="Load a sysex file.">Import from file</button>

                    <button type="button" className="btn btn-large btn-secondary"
                            onClick={this.exportAsFile}
                            title="Save the full configuration as a sysex file.">Export to file</button>

                    <button type="button" className="btn btn-large btn-info" onClick={this.print}
                            title="Open a popup with the current configuration displayed for printing or export to PDF.">Print</button>

                    <button className="btn btn-large btn-primary" onClick={this.help}
                            title="Open a popup with help information.">Help</button>

                    {expected > 0 &&
                    <div className={`midi-progress ${S.direction.toLowerCase()} ${all_done ? 'done' : ''}`}>
                        {S.direction} {S.what}
                        {/*{all_done ? 'done, please wait': `${S.direction} ${S.what}`}*/}
                    </div>}

                    <div className="spacer"> </div>
                    <Popup trigger={<div className="header-app-name">MIDI BABY editor {process.env.REACT_APP_VERSION}</div>} modal closeOnDocumentClick>
                        <div className="about">
                            <div className="about-title">MIDI BABY editor {process.env.REACT_APP_VERSION}</div>
                            <div>Coding by <a href="https://sysex.io/" target="_blank" rel="noopener noreferrer">Fran&ccedil;ois Georgy</a></div>
                            <div className="about-deps">
                                <div>This editor has been made possible thanks to the following libraries:</div>
                                <a href="https://github.com/immerjs/immer" target="_blank" rel="noopener noreferrer">immer</a>
                                <a href="https://mobx.js.org/" target="_blank" rel="noopener noreferrer">MobX</a>
                                <a href="https://github.com/mobxjs/mobx-react" target="_blank" rel="noopener noreferrer">mobx-react</a>
                                <br />
                                <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a>
                                <a href="https://react-popup.elazizi.com/" target="_blank" rel="noopener noreferrer">Reactjs-Popup</a>
                                <a href="https://github.com/jaywcjlove/store.js" target="_blank" rel="noopener noreferrer">store.js</a>
                                <br />
                                <a href="https://github.com/tonaljs/tonal" target="_blank" rel="noopener noreferrer">Tonal</a>
                                <a href="https://github.com/djipco/webmidi" target="_blank" rel="noopener noreferrer">WebMidi.js</a>
                            </div>
                            <div className="about-support">
                                <a href="https://www.disasterareaamps.com/files" target="_blank" rel="noopener noreferrer">MIDI Baby support page</a>
                            </div>
                        </div>
                    </Popup>
                </div>
            </Fragment>
        );
    }
}

export default inject('appState')(observer(Header));

