import {CMD_LOAD_GLOBAL_CONFIG} from "./constants";

// length is number of bytes excluding sysex header (1 byte), sys manufacturer (3 bytes) and sysex end (1 byte)
export const global_length = 22 - 1 - 3 - 1;

// offset 0 is first byte after manufacturer ID
export const global_offset = {
    command: 0,
    multijack: 1,
    led_brightness: 2,
    midi_in_channel: 3,
    midi_thru: 4,
    midi_clock: 5,
    usb: 6,
    led_state: 7,
    led2_state: 8,  // BABY3 only
    led3_state: 9,  // BABY3 only
    multijack2: 10  // BABY3 only
};

export const midi_thru_mask = {
    din: 0x01,          // bit 0
    multijack: 0x02,    // bit 1
    usb: 0x04           // bit 2
};

export function getDefaultGlobalSysex() {
    const data = new Array(global_length).fill(0);
    data[global_offset.command] = CMD_LOAD_GLOBAL_CONFIG;
    data[global_offset.midi_in_channel] = 16;
    data[global_offset.led_brightness] = 4;
    data[global_offset.midi_thru] = midi_thru_mask.din | midi_thru_mask.multijack | midi_thru_mask.usb;
    return data;
}
