import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import {
    MIDI_CLOCK, MIDI_CLOCK_LABEL, MODEL_BABY1, MODEL_BABY3, MODEL_LABEL,
    MULTIJACK,
    MULTIJACK_LABEL, SUB_CONFIG_SAVE_ENABLED, SYSEX_SIGNATURE, USB_FUNCTION,
    USB_FUNCTION_LABEL
} from "../model/constants";
import {global_offset} from "../model/global";
import {outputById} from "../utils/ports";
import "./GlobalConfig.css";
import {DIRECTION_WRITE} from "../state/State";
import {hs} from "../utils/hexstring";
import {wait} from "../utils/utils";
// import dompurify from 'dompurify';

// import Button from "./Button";

class GlobalConfig extends React.Component {

    state = {
        panel_open: true,
        multijackDescription: false,
        midiInDescription: false,
        midiThruDescription: false,
        midiClockDescription: false,
        usbDescription: false,
        ledDescription: false,
        ledStateDescription: false,
        fwDescription: false,
        save_help: false
    };

    togglePanel = () => {
        this.setState({panel_open: !this.state.panel_open});
    };

    setDirty = () => {
        // this.setState({dirty: true});
        this.props.appState.setGlobalDirty();
    };

    // clearDirty = () => {
    //     // this.setState({dirty: false});
    //     this.props.appState.setGlobalClean();
    // };

    setMultijack = (e) => {
        if (global.dev) console.log("setMultijack", e.target.value);
        const v = parseInt(e.target.value);
        this.props.appState.global[global_offset.multijack] = v;
        if (v !== MULTIJACK.fs && (this.props.appState.global[global_offset.led_state] > 3)) {
            this.props.appState.global[global_offset.led_state] = 0;
        }
        this.setDirty();
    };

    setMultijack2 = (e) => {
        if (global.dev) console.log("setMultijack2", e.target.value);
        const v = parseInt(e.target.value);
        this.props.appState.global[global_offset.multijack2] = v;
        //TODO: baby3, led + multijack ?
        // if (v !== MULTIJACK.fs && (this.props.appState.global[global_offset.led_state] > 3)) {
        //     this.props.appState.global[global_offset.led_state] = 0;
        // }
        this.setDirty();
    };

    handleLEDChange = (e) => {
        this.props.appState.global[global_offset.led_brightness] = parseInt(e.target.value);
        this.setDirty();
    };

    setLEDBrightness = (led, value) => {
        this.props.appState.global[led] = value;
        this.setDirty();
    };

    setMidiInChannel = (e) => {
        this.props.appState.global[global_offset.midi_in_channel] = parseInt(e.target.value);
        this.setDirty();
    };

    // setLEDState = (e) => {
    //     this.props.appState.global[global_offset.led_state] = parseInt(e.target.value);
    //     this.setDirty();
    // };

    setLEDState = (led, value) => {
        this.props.appState.global[led] = value;
        this.setDirty();
    };

    // handleMidiThruChange = (e) => {
    //     this.props.appState.global[global_offset.midi_thru] = parseInt(e.target.value);
    //     this.setDirty();
    // };

    handleMidiClockChange = (e) => {
        this.props.appState.global[global_offset.midi_clock] = parseInt(e.target.value);
        this.setDirty();
    };

    handleUSBFunctionChange = (e) => {
        this.props.appState.global[global_offset.usb] = parseInt(e.target.value);
        this.setDirty();
    };

    toggleMidiThru = (output) => {
        this.props.appState.toggleMidiThru(output);
        this.setDirty();
    };

    // showMultijack = () => {
    //     this.setState({multijackDescription: true});
    // };
    // hideMultijack = () => {
    //     this.setState({multijackDescription: false});
    // };

    setModelBaby1 = () => {
        this.props.appState.setModel(MODEL_BABY1);
    };

    setModelBaby3 = () => {
        this.props.appState.setModel(MODEL_BABY3);
    };

    save = async () => {

        const out = outputById(this.props.appState.midi.output);
        if (!out) {
            console.warn(`save: output ${this.props.appState.midi.output} not found`);
            return;
        }

        const data = this.props.appState.global;
        if (!data || data.length < 1) {
            console.warn(`save: global data is empty`);
            return;
        }

        this.props.appState.resetExpected(2);   // HACK to force the progress indicator to show; we set to 2 in order to show progress as 0..50..100 instead of just 100%
        this.props.appState.direction = DIRECTION_WRITE;
        this.props.appState.write_progress++;

        // this.props.appState.appendMessageOut([SYSEX_START, ...SYSEX_SIGNATURE, ...data, SYSEX_END]);

        if (global.dev) console.log(`save: send ${data.length} + 5 bytes`, hs(data));

        out.sendSysex(SYSEX_SIGNATURE, data);

        await wait(100);    // HACK to force the progress indicator to show

        this.props.appState.write_progress++;

        //TODO: read back and check that the save has been successful. If yes, clear the dirty flag.

        this.props.appState.setGlobalClean();
    };

    showSaveHelp = () => {
        this.setState({save_help: true});
    };

    hideSaveHelp = () => {
        this.setState({save_help: false});
    };

    render() {

        // if (global.dev) console.log(`%cGlobalConfig.render()`, "color: green; font-weight: bold");

        const G = this.props.appState.global;
        const S = this.props.appState;

        const panel_open = this.state.panel_open;
        const dirty = S.global_dirty;

        let v = null;
        let t = null;
        let show_version_warning = false;
        let show_connection_help = false;

        if (S.isBaby1()) {
            v = S.baby1_check_version;
            t = S.baby1_check_text;
        } else if (S.isBaby3()) {
            v = S.baby3_check_version;
            t = S.baby3_check_text;
        }
        // let warning = null;
        if (S.connected && S.device_ok && v && (S.version < v)) {
            // const sanitizer = dompurify.sanitize;
            // warning = sanitizer(t, {ADD_ATTR: ['target']});
            show_version_warning = true;
        }
        if (S.connected && !S.device_ok) {
            // const sanitizer = dompurify.sanitize;
            // warning = sanitizer(t, {ADD_ATTR: ['target']});
            show_connection_help = true;
        }

        // const v = getNameAndVersion(S.meta);

        const MJ = G[global_offset.multijack];
        const MJ2 = G[global_offset.multijack2];    // only valid when baby3

        const LED1States = [];
        for (let [value, label] of Object.entries(S.getLEDStateOptions(1))) {
            LED1States.push(<option key={`ledstate-1-${value}`} value={value}>{label}</option>);
        }
        let LED2States = null;
        let LED3States = null;
        if (S.isBaby3()) {
            LED2States = [];
            for (let [value, label] of Object.entries(S.getLEDStateOptions(2))) {
                LED2States.push(<option key={`ledstate-1-${value}`} value={value}>{label}</option>);
            }
            LED3States = [];
            for (let [value, label] of Object.entries(S.getLEDStateOptions(3))) {
                LED3States.push(<option key={`ledstate-1-${value}`} value={value}>{label}</option>);
            }
        }

        return (
            <div className={`global panel-wrapper ${panel_open ? '' : 'panel-closed'}`}>

                {show_version_warning &&
                <div className="global-information">
                    <div dangerouslySetInnerHTML={{ __html: t }} />
                </div>}

                {show_connection_help &&
                <div className="global-information connection-help">
                    <div>
                    If your MIDI Baby is not recognized, or if the MIDI port appears and quickly disappears, your Baby may be set to USB HOST rather than USB MIDI.
                    </div>
                    <div>
                    1. Plug in the USB cable, then wait until the LED starts flashing.<br />
                    2. Hold the (center) footswitch down, you’ll see the LED blink white once.
                </div>
                    <div>
                    The Baby will now be in USB MIDI mode for editing, and will revert back to USB Host mode on next boot unless you change it.
                    </div>
                    <div>
                    If you are using your Baby as a USB HOST (Source Audio C4, Zoom MultiStomp, etc) then you can leave the setting as HOST, otherwise we recommend setting this to USB MIDI and then using the Write Device command to save this setting.
                    </div>
                    <div>
                        If setting the Baby to USB MIDI does not fix the issue,<br />please contact <a href="https://www.disasterareadesigns.com/support" target="_blank" rel="noopener noreferrer">Disaster Area support</a>.
                    </div>
                </div>}

                {SUB_CONFIG_SAVE_ENABLED && dirty && S.connected &&
                <div className="button-save-button" >
                    {this.state.save_help && <div className="help">
                        Write the <span className="b">Global Settings</span> configuration in the MIDI Baby.<br />
                        Tip: use the <span className="b">Write Device</span> button to save <span className="b">everything</span> at once.
                    </div>}
                    <button type="button" className="btn btn-danger" onClick={this.save} onMouseOver={this.showSaveHelp} onMouseOut={this.hideSaveHelp}>Save</button>
                </div>
                }

                <div className="panel-title">
                    <div className="panel-toggle" onClick={this.togglePanel} title={panel_open ? 'Click to hide' : 'Click to show'}>Global settings</div>
{/*
                    {S.info.global && <span className="title-info">{S.info.global}</span>}
                    {!S.info.global && <span className="title-info">not sync</span>}
                    {SUB_CONFIG_SAVE_ENABLED && dirty && <button type="button" className="btn btn-danger" disabled={!S.connected} onClick={this.save}>Save</button>}
                    {!SUB_CONFIG_SAVE_ENABLED && dirty && S.connected &&
                    <div className="save-reminder">Config modified. Click <strong>Write device</strong> button to save your modifications.</div>
                    }
*/}
                </div>

                <div className={`panel-content ${S.isBaby3() ? "grid-12x2" : "grid-7x2"}`}>
{/*
                    {this.state.multijackDescription &&
                    <div className="float-description">
                        <div className="descr-title">MultiJack</div>
                        <div>Configures the 1/4” jack of MIDI Baby:</div>
                        <ul>
                            <li>MIDI Tip - send MIDI on jack tip for Alexander, Empress, Meris</li>
                            <li>MIDI Ring - send MIDI on jack ring for Chase Bliss (use TRS cable)</li>
                            <li>MIDI I/O - send and receive MIDI on jack</li>
                            <li>Expression Pedal - use TRS compatible expression pedal</li>
                            <li>External Footswitch - connect 1 or 2 button foot switch</li>
                        </ul>
                    </div>}

                    {this.state.midiInDescription &&
                    <div className="float-description">
                        <div className="descr-title">MIDI Input Channel</div>
                        <div>Configures the MIDI channel MIDI Baby will receive</div>
                    </div>}
                    {this.state.midiThruDescription &&
                    <div className="float-description">
                        <div className="descr-title">MIDI Thru</div>
                        <div>Routes incoming MIDI from USB or MultiJack to DIN / USB / Jack</div>
                    </div>}
                    {this.state.midiClockDescription &&
                    <div className="float-description">
                        <div className="descr-title">MIDI Clock</div>
                        <div>Enable or disable built-in MIDI clock</div>
                    </div>}
                    {this.state.usbDescription &&
                    <div className="float-description">
                        <div className="descr-title">USB Function</div>
                        <ul>
                            <li>MIDI for DAW connection or configuration</li>
                            <li>HOST to connect to Red Panda, Zoom, Source Audio C4</li>
                        </ul>
                    </div>}
                    {this.state.ledDescription &&
                    <div className="float-description">
                        <div className="descr-title">LED Brightness</div>
                        <div>Sets maximum power for LED</div>
                    </div>}
                    {this.state.ledStateDescription &&
                    <div className="float-description">
                        <div className="descr-title">LED off state</div>
                        <div>Configures the LED when no button is pressed.</div>
                        <ul>
                            <li>Blink during use only - LED is off when button is not pressed</li>
                            <li>Follow FS press - LED shows the select FS batch position</li>
                        </ul>
                    </div>}
                    {this.state.fwDescription &&
                    <div className="float-description">
                        <div className="descr-title">Firmware Version</div>
                        <div>Displays device type and firmware version</div>
                    </div>}
*/}

                    {S.isBaby1() && <div>MultiJack:</div>}
                    {S.isBaby3() && <div>MultiJack left (black):</div>}
                    {/*<div className="multijack" onMouseOver={() => this.setState({multijackDescription: true})} onMouseOut={() => this.setState({multijackDescription: false})}>*/}
                    <div className="multijack">
                        <div className="values-row">
                            <label onClick={(e) => e.stopPropagation()} className={MJ === MULTIJACK.tip ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.tip} checked={MJ === MULTIJACK.tip} onChange={this.setMultijack}/>
                                {MULTIJACK_LABEL[MULTIJACK.tip]}
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ === MULTIJACK.ring ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.ring} checked={MJ === MULTIJACK.ring} onChange={this.setMultijack}/>
                                {MULTIJACK_LABEL[MULTIJACK.ring]}
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ === MULTIJACK.both ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.both} checked={MJ === MULTIJACK.both} onChange={this.setMultijack}/>
                                {MULTIJACK_LABEL[MULTIJACK.both]}
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ === MULTIJACK.io ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.io} checked={MJ === MULTIJACK.io} onChange={this.setMultijack}/>
                                MIDI I/O
                            </label>
                        </div>
                        <div className="values-row">
                            <label onClick={(e) => e.stopPropagation()} className={MJ === MULTIJACK.fs ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.fs} checked={MJ === MULTIJACK.fs} onChange={this.setMultijack}/>
                                External footswitch
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ === MULTIJACK.exp ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.exp} checked={MJ === MULTIJACK.exp} onChange={this.setMultijack}/>
                                Expression pedal
                            </label>
                        </div>
                    </div>

                    {S.isBaby3() &&
                    <div>MultiJack right (red):</div>
                    }
                    {/*<div className="multijack" onMouseOver={() => this.setState({multijackDescription: true})} onMouseOut={() => this.setState({multijackDescription: false})}>*/}
                    {S.isBaby3() &&
                    <div className="multijack">
                        <div className="values-row">
                            <label onClick={(e) => e.stopPropagation()} className={MJ2 === MULTIJACK.tip ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.tip} checked={MJ2 === MULTIJACK.tip} onChange={this.setMultijack2}/>
                                {MULTIJACK_LABEL[MULTIJACK.tip]}
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ2 === MULTIJACK.ring ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.ring} checked={MJ2 === MULTIJACK.ring} onChange={this.setMultijack2}/>
                                {MULTIJACK_LABEL[MULTIJACK.ring]}
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ2 === MULTIJACK.both ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.both} checked={MJ2 === MULTIJACK.both} onChange={this.setMultijack2}/>
                                {MULTIJACK_LABEL[MULTIJACK.both]}
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ2 === MULTIJACK.io ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.io} checked={MJ2 === MULTIJACK.io} onChange={this.setMultijack2}/>
                                MIDI I/O
                            </label>
                        </div>
                        <div className="values-row">
                            <label onClick={(e) => e.stopPropagation()} className={MJ2 === MULTIJACK.fs ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.fs} checked={MJ2 === MULTIJACK.fs} onChange={this.setMultijack2}/>
                                External footswitch
                            </label>
                            <label onClick={(e) => e.stopPropagation()} className={MJ2 === MULTIJACK.exp ? 'checked' : ''}>
                                <input type="radio" value={MULTIJACK.exp} checked={MJ2 === MULTIJACK.exp} onChange={this.setMultijack2}/>
                                Expression pedal
                            </label>
                        </div>
                    </div>
                    }

                    <div>MIDI thru:</div>
                    {/*<div className="values-row" onMouseOver={() => this.setState({midiThruDescription: true})} onMouseOut={() => this.setState({midiThruDescription: false})}>*/}
                    <div className="values-row">
                        <label onClick={(e) => e.stopPropagation()} className={S.getMidiThru('din') ? 'checked' : ''}>
                            <input type="checkbox" checked={S.getMidiThru('din')} onChange={() => this.toggleMidiThru('din')}/>
                            DIN
                        </label>
                        <label onClick={(e) => e.stopPropagation()} className={S.getMidiThru('usb') ? 'checked' : ''}>
                            <input type="checkbox" checked={S.getMidiThru('usb')} onChange={() => this.toggleMidiThru('usb')}/>
                            USB
                        </label>
                        <label onClick={(e) => e.stopPropagation()} className={S.getMidiThru('multijack') ? 'checked' : ''}>
                            <input type="checkbox" checked={S.getMidiThru('multijack')} onChange={() => this.toggleMidiThru('multijack')}/>
                            Multijack
                        </label>
                    </div>

                    <div>MIDI clock:</div>
                    {/*<div className="values-row" onMouseOver={() => this.setState({midiClockDescription: true})} onMouseOut={() => this.setState({midiClockDescription: false})}>*/}
                    {S.isBaby1() &&
                    <div className="values-row">
                        {Object.values(MIDI_CLOCK).map(v => {
                            const checked = v === G[global_offset.midi_clock];
                            return (
                                <label key={`${v}-${checked}`} onClick={(e) => e.stopPropagation()} className={checked ? 'checked' : ''}>
                                    <input type="radio" value={v} checked={checked} onChange={this.handleMidiClockChange}/>
                                    {MIDI_CLOCK_LABEL[v]}
                                </label>
                            );
                        })}
                    </div>}

                    {S.isBaby3() &&
                    <div className="values-row">
                        <label onClick={(e) => e.stopPropagation()} className={G[global_offset.midi_clock] === 0 ? 'checked' : ''}>
                            <input type="radio" value={0} checked={G[global_offset.midi_clock] === 0} onChange={this.handleMidiClockChange}/>
                            {MIDI_CLOCK_LABEL[0]}
                        </label>
                        <label onClick={(e) => e.stopPropagation()} className={G[global_offset.midi_clock] > 0 ? 'checked' : ''}>
                            <input type="radio" value={1} checked={G[global_offset.midi_clock] > 0} onChange={this.handleMidiClockChange}/>
                            {MIDI_CLOCK_LABEL[1]}
                        </label>
                        {(G[global_offset.midi_clock] > 0) &&
                        <select onChange={this.handleMidiClockChange} value={G[global_offset.midi_clock]}>
                            <option value={3}>use left LED</option>
                            <option value={2}>use center LED</option>
                            <option value={1}>use right LED</option>
                        </select>}
                    </div>}

                    <div>USB function:</div>
                    {/*<div className="values-row" onMouseOver={() => this.setState({usbDescription: true})} onMouseOut={() => this.setState({usbDescription: false})}>*/}
                    <div className="values-row">
                        {Object.values(USB_FUNCTION).map(v => {
                            const checked = v === G[global_offset.usb];
                            return (
                                <label key={`${v}-${checked}`} onClick={(e) => e.stopPropagation()} className={checked ? 'checked' : ''}>
                                    <input type="radio" value={v} checked={checked} onChange={this.handleUSBFunctionChange}/>
                                    {USB_FUNCTION_LABEL[v]}
                                </label>
                            );
                        })}
                    </div>

                    <div>MIDI input channel:</div>
                    {/*<div className="values-row" onMouseOver={() => this.setState({midiInDescription: true})} onMouseOut={() => this.setState({midiInDescription: false})}>*/}
                    <div className="values-row">
                        <select onChange={this.setMidiInChannel} value={G[global_offset.midi_in_channel]}>
                            {Array.from(Array(16).keys()).map(i => <option key={i+1} value={i+1}>{i+1}</option>)}
                        </select>
                    </div>

                    {S.isBaby1() && <div>LED brightness:</div>}
                    {S.isBaby3() && <div>LEDs brightness:</div>}
                    {/*<div className="values-row" onMouseOver={() => this.setState({ledDescription: true})} onMouseOut={() => this.setState({ledDescription: false})}>*/}
                    <div className="values-row">
                        <div>
                            <input list="ticks" className="slider width-120" type="range" min="0" max="5" step="1"
                                   value={G[global_offset.led_brightness]} onChange={e => this.setLEDBrightness(global_offset.led_brightness, parseInt(e.target.value))} />
                            <datalist id="tics">
                                <option value="0" label="off" />
                                <option value="1" />
                                <option value="2" label="def" />
                                <option value="3" />
                                <option value="4" />
                                <option value="5" label="max"  />
                            </datalist>
                        </div>
                        <div>{G[global_offset.led_brightness]}</div>
                    </div>

                    {S.isBaby1() &&
                    <Fragment>
                        <div>LED off state:</div>
                        <div className="values-row">
                            <select onChange={e => this.setLEDState(global_offset.led_state, parseInt(e.target.value))} value={G[global_offset.led_state]}>
                                {LED1States}
                            </select>
                        </div>
                    </Fragment>}

                    {S.isBaby3() &&
                    <Fragment>
                        <div>Left LED off state:</div>
                        {/*<div className="values-row" onMouseOver={() => this.setState({ledStateDescription: true})} onMouseOut={() => this.setState({ledStateDescription: false})}>*/}
                        <div className="values-row">
                            <select onChange={e => this.setLEDState(global_offset.led3_state, parseInt(e.target.value))} value={G[global_offset.led3_state]}>
                                {LED3States}
                            </select>
                        </div>
{/*
                        <div>LED 2 brightness:</div>
                        <div className="values-row" onMouseOver={() => this.setState({ledDescription: true})} onMouseOut={() => this.setState({ledDescription: false})}>
                            <div>
                                <input list="ticks" className="slider width-120" type="range" min="0" max="5" step="1"
                                       value={G[global_offset.led_brightness]} onChange={e => this.setLEDBrightness(global_offset.led_brightness, parseInt(e.target.value))} />
                                <datalist id="tics">
                                    <option value="0" label="off" />
                                    <option value="1" />
                                    <option value="2" label="def" />
                                    <option value="3" />
                                    <option value="4" />
                                    <option value="5" label="max"  />
                                </datalist>
                            </div>
                            <div>{G[global_offset.led_brightness]}</div>
                        </div>
*/}
                        <div>Center LED off state:</div>
                        {/*<div className="values-row" onMouseOver={() => this.setState({ledStateDescription: true})} onMouseOut={() => this.setState({ledStateDescription: false})}>*/}
                        <div className="values-row">
                            <select onChange={e => this.setLEDState(global_offset.led2_state, parseInt(e.target.value))} value={G[global_offset.led2_state]}>
                                {LED2States}
                            </select>
                        </div>
{/*
                        <div>LED 3 brightness:</div>
                        <div className="values-row" onMouseOver={() => this.setState({ledDescription: true})} onMouseOut={() => this.setState({ledDescription: false})}>
                            <div>
                                <input list="ticks" className="slider width-120" type="range" min="0" max="5" step="1"
                                       value={G[global_offset.led_brightness]} onChange={this.handleLEDChange} />
                                <datalist id="tics">
                                    <option value="0" label="off" />
                                    <option value="1" />
                                    <option value="2" label="def" />
                                    <option value="3" />
                                    <option value="4" />
                                    <option value="5" label="max"  />
                                </datalist>
                            </div>
                            <div>{G[global_offset.led_brightness]}</div>
                        </div>
*/}
                        <div>Right LED off state:</div>
                        {/*<div className="values-row" onMouseOver={() => this.setState({ledStateDescription: true})} onMouseOut={() => this.setState({ledStateDescription: false})}>*/}
                        <div className="values-row">
                            <select onChange={e => this.setLEDState(global_offset.led_state, parseInt(e.target.value))} value={G[global_offset.led_state]}>
                                {LED1States}
                            </select>
                        </div>
                    </Fragment>
                    }

                    <div>Firmware version:</div>
                    {/*<div className="values-row" onMouseOver={() => this.setState({fwDescription: true})} onMouseOut={() => this.setState({fwDescription: false})}>*/}
                    <div className="values-row">
                        {S.connected && S.device_ok &&
                        <div>
                            {MODEL_LABEL[S.model]} - Version {S.version ? ` ${S.version}` : 'unknown'}
                        </div>}
                        {S.connected && !S.device_ok &&
                        <div className="help-text" style={{paddingTop:"2px"}}>
                            Unknown. Check the MIDI ports settings.
                            <div>
                            </div>
                        </div>}

                        {!S.connected &&
                        <div className="model-chooser">
                            <div className="bold">No MIDI Baby connected.</div>
                            <div>
                                You can still use the editor to edit sysex files.
                            </div>
                            <div>
                                Choose the MIDI Baby you want to edit:
                                <label onClick={(e) => e.stopPropagation()} className={S.model === MODEL_BABY1 ? 'left-space checked' : 'left-space'}>
                                    <input type="radio" value={MODEL_BABY1} checked={S.model === MODEL_BABY1} onChange={this.setModelBaby1}/>
                                    {MODEL_LABEL[MODEL_BABY1]}
                                </label>
                                <label onClick={(e) => e.stopPropagation()} className={S.model === MODEL_BABY3 ? 'left-space checked' : 'left-space'}>
                                    <input type="radio" value={MODEL_BABY3} checked={S.model === MODEL_BABY3} onChange={this.setModelBaby3}/>
                                    {MODEL_LABEL[MODEL_BABY3]}
                                </label>
                            </div>
                        </div>}

                    </div>

                </div>

            </div>
        );
    }
}

// https://github.com/mobxjs/mobx-react/issues/250
export default inject('appState')(observer(GlobalConfig));
