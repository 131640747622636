import React from "react";
import {inject, observer} from "mobx-react";
import Midi, {ERROR_MIDI_NOT_ENABLED, ERROR_MIDI_NOT_SUPPORTED, ERROR_UNKNOWN} from "./Midi";
import PortsGrid from "./PortsGrid";
import {isSysexData} from "../model/sysex";
import "./MidiHandler.css";
import {getNameAndVersion} from "../model";
import {MODEL_UNKNOWN} from "../model/constants";
import {loadPreferences} from "../utils/preferences";
import {h, hs} from "../utils/hexstring";

const RECEIVE_MSG_TYPES = "midimessage";  // only receive sysex messages

class MidiHandler extends React.Component {

    state = {
        error: null
    };

    onMidiError = (error) => {
        if (global.dev) console.warn("MidiHandler.onMidiError", error);
        this.setState({error})
    };

    onInputConnection = (port_id) => {
        if (global.dev) console.log("MidiHandler.onInputConnection", port_id);
        this.props.appState.setMidiInput(port_id);
    };

    onInputDisconnection = () => {
        if (global.dev) console.log("MidiHandler.onInputDisconnection");
        this.props.appState.setMidiInput(null);
    };

    onOutputConnection = (port_id) => {
        if (global.dev) console.log("MidiHandler.onOutputConnection", port_id);
        this.props.appState.setMidiOutput(port_id);
    };

    onOutputDisconnection = () => {
        if (global.dev) console.log("MidiHandler.onOutputDisconnection");
        this.props.appState.setMidiOutput(null);
    };

    handleMidiInputEvent = (e) => {

        // console.log(e);

        if (e.data[0] === 0xF8) {
            // we ignore Timing Clock messages
            return;
        }

        if (global.trace) console.log("MidiHandler.handleMidiInputEvent", hs(e.data), e);

        // e.data is UInt8Array
        const S = this.props.appState;

        // S.appendMessageIn(e);

        const expected = this.props.appState.isExpected(e.data);
        if (expected !== null) {
            if (global.dev) console.log("handleMidiInputEvent: set device_ok = true");
            S.device_ok = true;         //TODO: why set to true here?
            if (expected !== true) {
                if (global.dev) console.log("handleMidiInputEvent: expected msg can be ignored");
                return;
            }
        }

        //                       0  1  2  3  4  5  6  7  8  9
        // sysex version baby1: F0 7E 00 06 02 00 02 17 0B 0B 00 00 00 05 F7
        // sysex version baby3: F0 7E 00 06 02 00 02 17 0B 03 00 00 00 05 F7
        if (e.data[0] === 0xF0 && e.data[1] === 0x7E) {

            if (global.dev) console.log("handleMidiInputEvent: received General System Information message", hs(e.data));

            if (e.data[3] === 0x06 && e.data[4] === 0x02) {

                if (global.dev) console.log("handleMidiInputEvent: message is Device Identity Reply");

                // let a = new Array(S.meta.length).fill(0);
                // for (let i = 0, len = e.data.length; i < len; ++i) a[i] = e.data[i];
                // S.meta = a;

                let mv = getNameAndVersion(e.data);
                if (global.dev) console.log("handleMidiInputEvent: model and version:", mv, h(mv.model));

                if (mv.model !== MODEL_UNKNOWN) S.device_ok = true;

                S.setModel(mv.model);
                S.setVersion(mv.version);

            } else {
                if (global.dev) console.log("handleMidiInputEvent: message is not Device Identity Reply; ignore.");
            }
            return;
        }

        if (!isSysexData(e.data)) {
            if (global.dev) console.log("handleMidiInputEvent: not a sysex message");
            return;
        }

        S.importSysexDump(e.data);
    };

    componentDidMount(){
        const s = loadPreferences();
        if (s.auto_sync === undefined) {
            // undefined because not in pref yet
            this.props.appState.auto_sync = false;
        } else {
            this.props.appState.auto_sync = s.auto_sync;
        }
        //FIXME: use !! operator
    }

    render() {

        const S = this.props.appState;

        const deviceInputPortID = S.device_ok ? S.midi.input : null;
        const deviceOutputPortID = S.device_ok ? S.midi.output : null;

        let error_message = null;
        if (this.state.error) {
            switch (this.state.error.code) {
                case ERROR_MIDI_NOT_SUPPORTED :
                    error_message = 'Your browser does not support Web MIDI. We recommend Chrome browser.';
                    break;
                case ERROR_MIDI_NOT_ENABLED :
                    error_message = "You need to enable the access to MIDI devices in your browser. See Help for instructions.";
                    break;
                case ERROR_UNKNOWN :
                default:
                    error_message = "Unable to access MIDI. Unknown error.";
                    break;
            }
        }

        return (
            <div className="subheader">
                <div className="subheader-left">
                    <Midi portsRenderer={(groupedPorts, togglePortHandler) => <PortsGrid groupedPorts={groupedPorts} togglePortHandler={togglePortHandler} deviceInputPortID={deviceInputPortID} deviceOutputPortID={deviceOutputPortID} />}
                          messageType={RECEIVE_MSG_TYPES}
                          onError={this.onMidiError}
                          onMidiInputEvent={this.handleMidiInputEvent}
                          onInputConnection={this.onInputConnection}
                          onInputDisconnection={this.onInputDisconnection}
                          onOutputConnection={this.onOutputConnection}
                          onOutputDisconnection={this.onOutputDisconnection} />
                    {!error_message && !S.connected && <div className="instruction"><span className="tip">Enable the input and output ports to which the MIDI Baby is connected.</span></div>}
                    {S.connected && !S.device_ok && <div className="instruction"><span className="warning">The connected device is not recognized as a MIDI Baby.</span></div>}
                    {error_message &&
                    <div className="error"><div>{error_message}</div></div>}
                </div>
            </div>
        );
    }

}

export default inject('appState')(observer(MidiHandler));
