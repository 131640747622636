import React from 'react';
import Switch from "react-switch";
import "./PortsGrid.css";

const PortsGrid = ({ groupedPorts, togglePortHandler, deviceInputPortID, deviceOutputPortID }) => {

    return (
        <div className="ports-grid">
            {Object.keys(groupedPorts).map((name, index) => {
                const in_ok = groupedPorts[name].input && deviceInputPortID && (groupedPorts[name].input.id === deviceInputPortID);
                const out_ok = groupedPorts[name].output && deviceOutputPortID && (groupedPorts[name].output.id === deviceOutputPortID);
                // const in_ok = true;
                // const out_ok = true;
                return (
                    <div className={`port ${in_ok && out_ok ? 'device-ok' : ''}`} key={index}>
                        <div className="port-name">{name}</div>
                        {groupedPorts[name].input &&
                        <div className="port-switch">
                            in&nbsp;
                            <Switch onChange={() => togglePortHandler(groupedPorts[name].input.id)}
                                    checked={groupedPorts[name].input.selected} id={`switch-${groupedPorts[name].input.id}`}
                                    className={`react-switch ${in_ok ? 'device-ok' : ''}`}
                                    height={16} width={36}/>
                        </div>}
                        {groupedPorts[name].output &&
                        <div className="port-switch">
                            out&nbsp;
                            <Switch onChange={() => togglePortHandler(groupedPorts[name].output.id)}
                                    checked={groupedPorts[name].output.selected}
                                    id={`switch-${groupedPorts[name].output.id}`}
                                    className={`react-switch ${out_ok ? 'device-ok' : ''}`}
                                    height={16} width={36}/>
                        </div>}
                    </div>)
                }
            )}
        </div>
    );

};

export default PortsGrid;
