import React from "react";
import {inject, observer} from "mobx-react";
import {
    ACTION, SUB_CONFIG_SAVE_ENABLED, SYSEX_SIGNATURE
} from "../model/constants";
import {outputById} from "../utils/ports";
import {offset} from "../model/preset";
import ExpMessagesSummary from "./ExpMessagesSummary";
import ExpMessagesEditor from "./ExpMessagesEditor";
import {WAIT_BETWEEN_MESSAGES} from "../model";
import {wait} from "../utils/utils";
import {hs} from "../utils/hexstring";
import "./Exp.css";

class Exp extends React.Component {

    state = {
        edit: false,
        panel_open: true,
        save_help: false
    };

    togglePanel = () => {
        this.setState({panel_open: !this.state.panel_open});
    };

    editAction = () => {
        this.setState({edit: true});
    };

    closeEdit = () => {
        this.setState({edit: false});
    };

    showSaveHelp = () => {
        this.setState({save_help: true});
    };

    hideSaveHelp = () => {
        this.setState({save_help: false});
    };

    setMidiInChannel = (e) => {
        this.props.appState.preset[this.props.button][ACTION.exp][offset('channel', 0)] = e.target.value;
        this.props.appState.setPresetDirty(this.props.button);
    };

    save = (data) => {

        //TODO: pass button number in parameter

        const out = outputById(this.props.appState.midi.output);

        if (!out) {
            console.warn(`save: output ${this.props.appState.midi.output} not found`);
            return;
        }

        if (!data || data.length < 1) {
            console.warn(`save: global data is empty`);
            return;
        }

        if (global.dev) console.log(`save: send ${data.length + 5} bytes`, hs(data));

        out.sendSysex(SYSEX_SIGNATURE, data);

        //TODO: read back and check that the save has been successful. If yes, clear the dirty flag.
    };

    saveButton = () => {
        this.save(this.props.appState.button[this.props.button]);
    };

    savePreset = (action) => {
        this.save(this.props.appState.presetBytes(this.props.button, action));
    };

    saveButtonAndPreset = async () => {

        this.saveButton();

        await wait(WAIT_BETWEEN_MESSAGES);
        this.savePreset(ACTION.exp);
    };

    render() {

        const S = this.props.appState;

        const {button, name} = this.props;
        // const button = BUTTON.tip;
        const action = ACTION.tap;
        const panel_open = this.state.panel_open;
        const dirty = S.isButtonDirty(button) || S.isPresetDirty(button);
        const edit = this.state.edit;

        return (
            <div className={`button-editor panel-wrapper ${panel_open ? '' : 'panel-closed'}`}>

                {SUB_CONFIG_SAVE_ENABLED && dirty && this.props.appState.connected &&
                <div className="button-save-button" >
                    {this.state.save_help && <div className="help">
                        Write the <span className="b">{name}</span> configuration in the MIDI Baby.<br />
                        Tip: use the <span className="b">Write Device</span> button to save <span className="b">everything</span> at once.
                    </div>}
                    <button type="button" className="btn btn-danger" onClick={this.saveButtonAndPreset} onMouseOver={this.showSaveHelp} onMouseOut={this.hideSaveHelp}>Save</button>
                </div>
                }

                <div className="panel-title">
                    <div className="panel-toggle" onClick={this.togglePanel} title={panel_open ? 'Click to hide' : 'Click to show'}>{name}</div>
                </div>

                <div className="panel-content content-wrapper">
                    <div className="button-actions">

                        <div className={`button-action color-0`}>

                            <div className="action-edit-button">
                                {!edit && <button type="button" className="btn btn-primary" onClick={this.editAction}>Edit this action</button>}
                                {edit && <button type="button" className="btn btn-success" onClick={this.closeEdit}>Quit edit mode</button>}
                            </div>

                            <div className="action-name exp">
                                <div className="action-name-name exp">Exp</div>
                                <div className="instruction">&nbsp;</div>
                            </div>

                            {!edit && <ExpMessagesSummary button={button} action={action} />}

                            {edit && <ExpMessagesEditor button={button} action={action} />}

                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default inject('appState')(observer(Exp));
