import React from "react";
import {inject, observer} from "mobx-react";
import Button from "./Button";
import Exp from "./Exp";

class Buttons extends React.Component {

    render() {

        return (
            <div className="content">
                {this.props.appState.getButtons().map(
                    b => b.exp ?
                        <Exp key={`exp-${b.button}-${b.name}`} button={b.button} name={b.name} />  :
                        <Button key={`sw-${b.button}-${b.name}`} button={b.button} name={b.name} />
                )}
            </div>
        );
    }
}

export default inject('appState')(observer(Buttons));

