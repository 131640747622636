import React from "react";
import {inject, observer} from "mobx-react";
import {ACTION} from "../model/constants";
import ButtonAction from "./ButtonAction";
import {button_offset} from "../model/button";
import "./Button.css";

const BEHAVIOR_IMMEDIATE = 0;
const BEHAVIOR_FALL_RISE = 1;
const BEHAVIOR_DELAY = 2;

class Button extends React.Component {

    getBehavior = () => {
        const t = this.props.appState.button[this.props.button][button_offset.hold_time];
        if (t === 0) {
            return BEHAVIOR_IMMEDIATE;
        } else if (t === 1) {
            return BEHAVIOR_FALL_RISE
        } else {
            return BEHAVIOR_DELAY;
        }
    };

    render() {

        // if (global.dev) console.log(`%cButton.render()`, "color: green; font-weight: bold");

        const {button, name} = this.props;
        const behavior = this.getBehavior();

        let behavior_label;
        switch (behavior) {
            case BEHAVIOR_IMMEDIATE:
                behavior_label = 'Immediate action on PRESS.';
                break;
            case BEHAVIOR_FALL_RISE:
                behavior_label = 'Send PRESS messages on falling edge, RELEASE messages on rising edge.';
                break;
            case BEHAVIOR_DELAY:
                behavior_label = 'Send PRESS messages on falling edge, HOLD and LONG-HOLD messages after the configured delay.';
                break;
            default:
                console.warn("invalid behavior value", behavior);
                behavior_label = '';
        }

        return (
            <div className={`button-editor panel-wrapper`}>

                <div className="panel-title">
                    {name}
                </div>

                <div className="label">{behavior_label}</div>

                <div className="button-actions">
                    <ButtonAction button={button} action={ACTION.tap} enabled={true} />
                    {behavior !== BEHAVIOR_IMMEDIATE && <ButtonAction button={button} action={ACTION.hold} />}
                    {behavior === BEHAVIOR_DELAY && <ButtonAction button={button} action={ACTION.long_hold} className="last" />}
                </div>

            </div>
        );
    }
}

export default inject('appState')(observer(Button));
