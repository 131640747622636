import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import ActionSummary from "./ActionSummary";
import {ACTION, COLOR_LABEL} from "../model/constants";
import {button_offset, humanDelay, humanHoldTime, humanLongHoldTime, offsetColor, offsetDelay} from "../model/button";
import "./ButtonAction.css";
import ActionEditor from "./ActionEditor";

const SEND_ALL_MESSAGES_AT_ONCE = 0;
const SEND_MESSAGES_IN_BATCH = 1;
const SEND_MESSAGES_ONE_BY_ONE = 2;

class ButtonAction extends React.Component {

    state = {
        edit: false     // action being edited; value is one of ACTION
    };

    editAction = () => {
        this.setState({edit: true});
    };

    closeEdit = () => {
        this.setState({edit: false});
    };

    setColor = (color) => {
        this.props.appState.button[this.props.button][offsetColor(this.props.action)] = parseInt(color);
        this.props.appState.setButtonDirty(this.props.button);
    };

    setHoldTime = (event) => {
        const v = parseInt(event.target.value);
        if (Number.isNaN(v)) {
            return false;
        }
        this.props.appState.button[this.props.button][button_offset.hold_time] = v;
        this.props.appState.setButtonDirty(this.props.button);
    };

    setLongHoldTime = (event) => {
        const v = parseInt(event.target.value);
        if (Number.isNaN(v)) {
            return false;
        }
        this.props.appState.button[this.props.button][button_offset.long_hold_time] = v;
        this.props.appState.setButtonDirty(this.props.button);
    };

    setDelayBetweenMessages = (event) => {
        const v = parseInt(event.target.value);
        // if (global.dev) console.log(`setDelayBetweenMessages: ${v}`);
        if (Number.isNaN(v)) {
            return false;
        }
        this.props.appState.button[this.props.button][offsetDelay(this.props.action)] = v;
        this.props.appState.setButtonDirty(this.props.button);
    };

    setSendMode = (e) => {
        let dly;
        switch (parseInt(e.target.value, 10)) {
            case SEND_ALL_MESSAGES_AT_ONCE:
                dly = 0;
                break;
            case SEND_MESSAGES_IN_BATCH:
                dly = 1;
                break;
            case SEND_MESSAGES_ONE_BY_ONE:
                dly = 20;
                break;
            default: dly = 0;
        }
        if (global.dev) console.log(`setSendMode: ${e.target.value} ${dly}`);
        this.props.appState.button[this.props.button][offsetDelay(this.props.action)] = dly;
        this.props.appState.setButtonDirty(this.props.button);
    };

    render() {

        if (global.dev) console.log("ButtonAction.render()");

        const { button, action, enabled } = this.props;

        const S = this.props.appState;
        const n = S.getNumberOfMessages(button, action);
        const hold = S.button[button][button_offset.hold_time];
        const long_hold = S.button[button][button_offset.long_hold_time];
        // if (global.dev) console.log("* button", button, action, hold, long_hold);

        const release_mode = hold === 1;

        let name;
        let description;
        switch (action) {
            case ACTION.tap:
                name = 'PRESS';
                description = 'Messages are sent immediately on press of the footswitch.';
                break;
            case ACTION.hold:
                if (release_mode) {
                    name = 'RELEASE';   //TODO: constants for strings
                    description = 'Messages are sent on release of the footswitch.';
                } else {
                    name = 'HOLD';
                    description = 'Messages are sent after the configured delay.';
                }
                break;
            case ACTION.long_hold:
                name = 'LONG HOLD';
                description = 'Messages are sent after the configured delay.';
                break;
            default:
                console.error("ButtonAction: invalid action:", action);
                name = 'TAP';
                description = '';
                break;
        }

/*
        let n_messages = '';
        switch (n) {
            case 0 : n_messages = 'There are no messages defined for this action.'; break;
            case 1 : n_messages = '1 message will be sent for this action:'; break;
            default: n_messages = `${n} messages will be sent for this action:`;
        }
*/

        const edit = this.state.edit;

        const dly = S.getDelayBetweenMessages(button, action);

        let current_send_mode;
        if (dly === 0) {
            current_send_mode = SEND_ALL_MESSAGES_AT_ONCE;
        } else if (dly <= 16) {
            current_send_mode = SEND_MESSAGES_IN_BATCH;
        } else {
            current_send_mode = SEND_MESSAGES_ONE_BY_ONE;
        }

        const action_enabled =
            edit ||
            (action === ACTION.tap) ||
            (action === ACTION.hold && hold > 0) ||
            (action === ACTION.long_hold && long_hold > 0);

        if (global.dev) console.log(`ButtonAction.render(): button=${button}, action=${action}, n=${n}, action_enabled=${action_enabled}`);

        const led_color = action_enabled ? `color-${S.button[button][offsetColor(action)]}` : 'ignored';

        let hold_delay_warning = null;
        if ((hold > long_hold) && (long_hold > 0)) {
            hold_delay_warning = <div className="left-space warning">Hold delay must be less than long-hold delay.</div>;
        } else if ((hold * 50) < 500) {
            hold_delay_warning = <div className="left-space help-text">It is recommended to set a delay of at least 500ms to avoid false triggering.</div>;
        }

        let long_hold_delay_warning = null;
        if ((hold > long_hold) && (hold > 0)) {
            long_hold_delay_warning = <div className="left-space warning">Long-hold delay must be less than hold delay.</div>;
        } else if ((long_hold * 50) < 500) {
            long_hold_delay_warning = <div className="left-space help-text">It is recommended to set a delay of at least 500ms to avoid false triggering.</div>;
        }

        const has_LED = S.isBaby1() ? button < 1 : button < 3;

        return (
            <div className={`button-action ${led_color} ${enabled ? '' : 'disabled'} ${this.props.className ? this.props.className : ''}`}>

                <div className="action-edit-button">
                    {!edit && <button type="button" className="btn btn-primary" onClick={this.editAction}>Edit this action</button>}
                    {edit && <button type="button" className="btn btn-success" onClick={this.closeEdit}>Quit edit mode</button>}
                </div>

                <div className={`action-name`}>
                    <div className={`action-name-name`}>On {name}:</div>
                    <div className="instruction">{description}</div>
                    {/* !edit && <button type="button" className="btn btn-primary btn-small" onClick={this.editAction}>Edit this action</button>}
                    {edit && <button type="button" className="btn btn-success btn-small" onClick={this.closeEdit}>Quit edit mode</button> */}
                </div>

                {action === ACTION.hold && !action_enabled &&
                <Fragment>
                    <div className="action-row span-2">
                        <div>To use this action, click Edit and configure the action with an delay greater than zero.</div>
                    </div>
                </Fragment>
                }

                {!release_mode && action === ACTION.hold && action_enabled &&
                <Fragment>
                    <div className="action-row action-label">Delay:
                        {edit && <div className="left-space"><input className="slider width-200" type="range" min="2" max="127" step="1" value={hold} onChange={this.setHoldTime} /></div>}
                        <div className="left-space bold">{humanHoldTime(hold)}</div>
                        {hold_delay_warning}
                    </div>
                </Fragment>
                }

                {action === ACTION.long_hold && !action_enabled &&
                <Fragment>
                    <div className="action-row span-2">
                        <div>To use this action, click Edit and configure the action with a delay greater than zero.</div>
                    </div>
                </Fragment>
                }

                {action === ACTION.long_hold && action_enabled &&
                <Fragment>
                    <div className="action-row action-label">Delay:
                        {edit && <div className="left-space"><input type="range" className="slider width-200" min="2" max="127" step="1" value={long_hold} onChange={this.setLongHoldTime} /></div>}
                        <div className="left-space bold">{humanLongHoldTime(long_hold)}</div>
                        {long_hold_delay_warning}
                    </div>
                </Fragment>
                }

                {action_enabled &&
                <Fragment>

                    {has_LED &&
                    <div className="action-row action-label action-with-select">LED color: {/*</div>*/}
                        {!edit && <span className="led-color">{COLOR_LABEL[S.getColor(button, action)]}</span>}
                        {edit &&
                            <select className="led-color" onChange={(event) => this.setColor(event.target.value)} value={S.button[button][offsetColor(action)]}>
                                {Object.entries(COLOR_LABEL).map(([k, v], i) => <option key={i} value={k}>{v}</option>)}
                            </select>
                        }
                    </div>}

                    {n === 0 &&
                    <div className="action-row">
                        There are no messages defined for this action.
                    </div>}

                    {n === 1 &&
                    <div className="action-row">
                        {!edit && <ActionSummary button={button} action={action} />}
                        {edit && <ActionEditor button={button} action={action} />}
                    </div>}

                    {n > 1 &&
                    <div className="action-row">

                        {n > 0 &&
                        <Fragment>
                            <div>Send</div>
                            {edit &&
                            <div>
                                <select onChange={this.setSendMode} value={current_send_mode}>
                                    <option value={SEND_ALL_MESSAGES_AT_ONCE}>all messages at once</option>
                                    <option value={SEND_MESSAGES_IN_BATCH}>messages in batch</option>
                                    <option value={SEND_MESSAGES_ONE_BY_ONE}>messages one-by-one</option>
                                </select>
                            </div>}

                            {current_send_mode === SEND_ALL_MESSAGES_AT_ONCE &&
                            <Fragment>
                                {!edit && <div>all messages at once</div>}
                            </Fragment>}

                            {current_send_mode === SEND_MESSAGES_IN_BATCH &&
                            <Fragment>
                                {!edit && <div>messages in batch</div>}
                                <div>with</div>
                                <div>
                                    {!edit && `${dly}`}
                                    {edit &&
                                    <select value={dly} onChange={this.setDelayBetweenMessages}>
                                        {Array.from(Array(16).keys()).map(i => <option key={i} value={i}>{i}</option>)}
                                    </select>}
                                </div>
                                <div>messages per action</div>
                            </Fragment>}

                            {current_send_mode === SEND_MESSAGES_ONE_BY_ONE &&
                            <Fragment>
                                {!edit && <div>messages one by one</div>}
                                <div>with a delay of</div>
                                {edit && <div><input className="slider width-120" type="range" min="17" max="127" step="1" size="4" value={dly} onChange={this.setDelayBetweenMessages}/></div>}
                                <div>{humanDelay(dly)}</div>
                                <div>between each message</div>
                            </Fragment>}
                        </Fragment>}
                    </div>}

                </Fragment>
                }

                {(n === 0 || n > 1) &&
                <Fragment>
                    {!edit && action_enabled && <ActionSummary button={button} action={action} />}
                    {edit && <ActionEditor button={button} action={action} />}
                </Fragment>}

            </div>
        );
    }
}

export default inject('appState')(observer(ButtonAction));
