import {ACTION, CMD_LOAD_PRESET} from "./constants";
import {MAX_BUTTONS} from "./button";
import {byteArray2stringArray, inputIntValue} from "../utils/utils";

// length is number of bytes excluding sysex header (1 byte), sys manufacturer (3 bytes) and sysex end (1 byte)
export const preset_length = 136 - 1 - 3 -1;

// offset 0 is first byte after manufacturer ID
export const preset_offset = {
    command: 0,
    preset_number: 1,
    preset_action: 2
};

export function getDefaultPresetSysex(preset_number, action) {
    const data = new Array(preset_length).fill(0);
    data[preset_offset.command] = CMD_LOAD_PRESET;
    data[preset_offset.preset_number] = preset_number;
    data[preset_offset.preset_action] = action;
    return data;
}

export function getPresetsStruct() {
    let presets = {};
    for (let button=0; button<MAX_BUTTONS; button++) {
        presets[button] = {
            [ACTION.tap]: byteArray2stringArray(getDefaultPresetSysex(button, ACTION.tap)),
            [ACTION.hold]: byteArray2stringArray(getDefaultPresetSysex(button, ACTION.hold)),
            [ACTION.long_hold]: byteArray2stringArray(getDefaultPresetSysex(button, ACTION.long_hold)),
            dirty: false
        };
    }
    return presets;
}

// offset 0 is the first byte after Manufacturer ID
// first message is number 0
export function offset(param, message_number) {
    switch (param.toLowerCase()) {
        case 'messagetype': return message_number + 3;          //TODO: define constants
        case 'controller': return message_number + 16 + 3;
        case 'channel': return message_number + (16*2) + 3;
        case 'startvalue': return message_number + (16*3) + 3;
        case 'endvalue': return message_number + (16*4) + 3;
        case 'step': return message_number + (16*5) + 3;
        case 'counter': return message_number + (16*6) + 3;
        default: return -1;  //TODO: log error
    }
}

export function offsetMessageType(message_number) {
    return message_number + 3;
}

export function offsetController(message_number) {
    return message_number + 16 + 3;
}

export function offsetChannel(message_number) {
    return message_number + (16*2) + 3;
}

export function offsetStartValue(message_number) {
    return message_number + (16*3) + 3;
}

export function offsetEndValue(message_number) {
    return message_number + (16*4) + 3;
}

export function offsetStep(message_number) {
    return message_number + (16*5) + 3;
}

export function offsetCounter(message_number) {
    return message_number + (16*6) + 3;     // # + 96 + 3
}

export function presetValue(preset, param, message_number) {
    let str_value;
    if (message_number === undefined) {
        str_value = preset[param];
    } else {
        str_value = preset[offset(param, message_number)];
    }
    // return !!str_value ? parseInt(str_value, 10) : 0;
    return inputIntValue(str_value);
}

