import {appState} from "../state/State.js";
import {outputById} from "./ports";
import {
    ACTION,
    CMD_LOAD_BUTTON_CONFIG,
    CMD_LOAD_GLOBAL_CONFIG, CMD_LOAD_PRESET, CMD_REQUEST_BUTTON_CONFIG,
    CMD_REQUEST_GLOBAL_CONFIG, CMD_REQUEST_PRESET,
    SYSEX_SIGNATURE,
    SYSEX_START
} from "../model/constants";
import {DIRECTION_READ, DIRECTION_WRITE} from "../state/State";
import {wait} from "./utils";
import {WAIT_BETWEEN_MESSAGES} from "../model";

/**
 * Function called when a device is connected.
 */
export async function deviceCheck() {

    if (global.dev) console.log(`%cdeviceCheck()`, "color: lightblue; font-weight: bold");

    const out = outputById(appState.midi.output);
    if (!out) {
        console.warn(`save: output ${appState.midi.output} not found`);
        return;
    }

    if (global.dev) console.log("deviceCheck: send Device Identity Request");
    //TODO: allow wildcard in expected messages (e.g. ID response)
    // appState.addExpected([SYSEX_START, 0x7E, 0x00, 0x06, 0x02, 0x00, 0x02, 0x17, 0x0B, 0x0B], true);
    appState.addExpected([SYSEX_START, 0x7E, 0x00, 0x06, 0x02, 0x00, 0x02, 0x17, 0x0B], true);
    out.sendSysex([0x7E, 0x00, 0x06], [0x01]);  // use sendSysex to bypass the webmidijs internal checks.

    //TODO: implement a messages queue

    // let response = await fetch('version.txt');
    // let data = await response.json()
    // console.log("VERSION.TXT", data)



/*
 * DOES NOT WORK RELIABLY
 *
    if (global.dev) console.log("deviceCheck: wait 400ms");
    await wait(1000);

    if (global.dev) console.log("deviceCheck: wait elapsed");
    if (appState.device_ok) {
        if (global.dev) console.log(`%cdeviceCheck: device ok; will read its config`, "color: lightblue; font-weight: bold");
        // readDevice();    // DOES NOT WORK RELIABLY
    } else {
        if (global.dev) console.log(`%cdeviceCheck: device NOT ok`, "color: lightred; font-weight: bold");
    }
*/
}

export function readVersion() {
    const out = outputById(appState.midi.output);
    if (!out) {
        console.warn(`save: output ${appState.midi.output} not found`);
        return;
    }
    //TODO: allow wildcard in expected messages (e.g. ID response)
    // appState.addExpected([SYSEX_START, 0x7E, 0x00, 0x06, 0x02, 0x00, 0x02, 0x17, 0x0B, 0x0B], true);
    appState.addExpected([SYSEX_START, 0x7E, 0x00, 0x06, 0x02, 0x00, 0x02, 0x17, 0x0B], true);
    out.sendSysex([0x7E, 0x00, 0x06], [0x01]);  // use sendSysex to bypass the webmidijs internal checks.
}

export function readGlobal() {
    const out = outputById(appState.midi.output);
    if (!out) {
        console.warn(`save: output ${appState.midi.output} not found`);
        return;
    }
    appState.addExpected([SYSEX_START, ...SYSEX_SIGNATURE, CMD_LOAD_GLOBAL_CONFIG], true);
    out.sendSysex(SYSEX_SIGNATURE, [CMD_REQUEST_GLOBAL_CONFIG]);
}

export function readButton(button) {
    const out = outputById(appState.midi.output);
    if (!out) {
        console.warn(`save: output ${appState.midi.output} not found`);
        return;
    }
    appState.addExpected([SYSEX_START, ...SYSEX_SIGNATURE, CMD_LOAD_BUTTON_CONFIG], true);
    out.sendSysex(SYSEX_SIGNATURE, [CMD_REQUEST_BUTTON_CONFIG, button]);
}

export function readPreset(button, action) {
    const out = outputById(appState.midi.output);
    if (!out) {
        console.warn(`save: output ${appState.midi.output} not found`);
        return;
    }
    appState.addExpected([SYSEX_START, ...SYSEX_SIGNATURE, CMD_LOAD_PRESET], true);
    out.sendSysex(SYSEX_SIGNATURE, [CMD_REQUEST_PRESET, button, action]);
}

export async function readDevice()  {

    // First we need to read the ID of the device to know if we have a Baby1 or a Baby3.

    appState.reset();

    appState.resetExpected(1);
    // appState.resetExpected(1 + (1 + MAX_BUTTONS * (1 + 3)));

    appState.direction = DIRECTION_READ;
    appState.what = 'version';
    readVersion();
    await wait(2 * WAIT_BETWEEN_MESSAGES);      // TODO: make two async functions; one to read the version, one to read the patch

    /**
     * BABY 1:
     *    0 Main FS
     *    1 MultiJack Tip / Exp
     *    2 MultiJack Ring
     * BABY 3:
     *    0 Right FS
     *    1 Center FS
     *    2 Left FS
     *    3 MultiJack 1 Tip / Exp
     *    4 MultiJack 1 Ring
     *    5 MultiJack 2 Tip / Exp
     *    6 MultiJack 2 Ring
     */

    const nb_buttons = appState.getNumberOfButtons();

    const nb_expected = (1 + nb_buttons * (1 + 3)); // global + nb_buttons * (button + 3 * preset) ; 3 * preset == switch + ext tip + ext ring

    if (global.dev) console.log(`readDevice: ${nb_buttons} buttons; ${nb_expected} expected messages`);

    appState.resetExpected(nb_expected);
    appState.what = 'global';
    await wait(WAIT_BETWEEN_MESSAGES);
    readGlobal();

    //TODO: redo with Promise.all() (https://medium.com/@antonioval/making-array-iteration-easy-when-using-async-await-6315c3225838)

    // appState.what = 'presets';
    for (let button=0; button < nb_buttons; button++) {
        if (global.dev) console.log(`readDevice: read button ${button}`);
        await wait(WAIT_BETWEEN_MESSAGES);
        appState.what = `button ${button}`;
        readButton(button);
        await wait(WAIT_BETWEEN_MESSAGES);
        appState.what = `button ${button}.`;
        readPreset(button, ACTION.tap);
        await wait(WAIT_BETWEEN_MESSAGES);
        appState.what = `button ${button}..`;
        readPreset(button, ACTION.hold);
        await wait(WAIT_BETWEEN_MESSAGES);
        appState.what = `button ${button}...`;
        readPreset(button, ACTION.long_hold);
    }
    // appState.what = `please wait`;
}

export function write(data) {
    const out = outputById(appState.midi.output);
    if (!out) {
        console.warn(`save: output ${appState.midi.output} not found`);
        return;
    }
    const S = appState;
    out.sendSysex(SYSEX_SIGNATURE, data);
    S.write_progress++;
}

export async function writeDevice() {

    const nb_buttons = appState.getNumberOfButtons();

    const nb_expected = (1 + nb_buttons * (1 + 3)); // global + nb_buttons * (button + 3 * preset) ; 3 * preset == switch + ext tip + ext ring

    if (global.dev) console.log(`writeDevice: ${nb_buttons} buttons; ${nb_expected} expected messages`);

    appState.resetExpected(nb_expected);
    appState.direction = DIRECTION_WRITE;

    //TODO: read back and check that the save has been successful. If yes, clear the dirty flag.

    const S = appState;

    if (global.dev) console.log("writeDevice: write global");
    appState.what = 'global';
    write(S.global);
    appState.setGlobalClean();

    for (let button=0; button < nb_buttons; button++) {

        await wait(2 * WAIT_BETWEEN_MESSAGES);

        if (global.dev) console.log(`writeDevice: write button ${button}`);
        appState.what = `button ${button}`;
        write(S.button[button]);
        appState.setButtonClean(button);

        await wait(2 * WAIT_BETWEEN_MESSAGES);
        if (global.dev) console.log(`writeDevice: write button ${button} action press`);
        appState.what = `button ${button}.`;
        write(S.presetBytes(button, ACTION.tap));

        await wait(2 * WAIT_BETWEEN_MESSAGES);
        if (global.dev) console.log(`writeDevice: write button ${button} action hold`);
        appState.what = `button ${button}..`;
        write(S.presetBytes(button, ACTION.hold));

        await wait(2 * WAIT_BETWEEN_MESSAGES);
        if (global.dev) console.log(`writeDevice: write button ${button} action long hold`);
        appState.what = `button ${button}...`;
        write(S.presetBytes(button, ACTION.long_hold));

        appState.setPresetClean(button);
    }

    //TODO: clear dirty after checking (re-read?)
    // await wait(100);
    if (global.dev) console.log("writeDevice: done");
}
