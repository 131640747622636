import React, {Component, Fragment} from 'react';
import {Provider} from "mobx-react";
import {appState} from "./state/State";
import Dropzone from "react-dropzone";
import {hs} from "./utils/hexstring";
import MidiHandler from "./view/MidiHandler";
import Header from "./view/Header";
import {readFiles} from "./utils/utils";
import Editor from "./view/Editor";
import './App.css';
import Information from "./view/Information";
import dompurify from "dompurify";

const dropOverlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: '240px',
    background: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    color: '#fff',
    fontSize: '4rem',
    zIndex: 10000
};

class App extends Component {

    state = {
        dropZoneActive: false
    };

    onDragEnter = () => {
        this.setState({dropZoneActive: true});
    };

    onDragLeave = () => {
        this.setState({dropZoneActive: false});
    };

    /**
     * Drop Zone handler
     * @param files
     */
    onDrop = async (files) => {
        this.setState({dropZoneActive: false});
        const bytes = await readFiles(files);
        if (global.dev) console.log("onDrop: bytes read", hs(bytes));
        appState.importSysexDump(bytes, true);
    };

    readVersionFiles = async() => {
        const sanitizer = dompurify.sanitize;

        let response = await fetch('version-baby1.txt');
        if (global.dev) console.log("VERSION1.TXT", response);
        if (response.status === 200) {
            let data = await response.text();
            // console.log("DATA1", data);
            let lines = data.split('\n');
            if (lines.length >= 2) {
                let v_str = lines.splice(0, 1)[0];
                let v = parseInt(v_str.trim());
                if (Number.isNaN(v)) {
                    console.warn("invalid version in version-baby1.txt");
                } else {
                    appState.baby1_check_version = v;
                    appState.baby1_check_text = sanitizer(lines.join('\n'), {ADD_ATTR: ['target']});
                    if (global.dev) console.log("VER1", appState.baby1_check_version);
                    if (global.dev) console.log("MSG1", appState.baby1_check_text);
                }
            } else {
                if (global.dev) console.warn("invalid version-baby1.txt");
            }
        } else {
            if (global.dev) console.log("ERR1");
        }
        response = await fetch('version-baby3.txt');
        if (global.dev) console.log("VERSION3.TXT", response);
        if (response.status === 200) {
            let data = await response.text();
            // console.log("DATA3", data);
            let lines = data.split('\n');
            if (lines.length >= 2) {
                let v_str = lines.splice(0, 1)[0];
                let v = parseInt(v_str.trim());
                if (Number.isNaN(v)) {
                    console.warn("invalid version in version-baby3.txt");
                } else {
                    appState.baby3_check_version = v;
                    appState.baby3_check_text = sanitizer(lines.join('\n'), {ADD_ATTR: ['target']});
                    if (global.dev) console.log("VER3", appState.baby3_check_version);
                    if (global.dev) console.log("MSG3", appState.baby3_check_text);
                }
            } else {
                if (global.dev) console.warn("invalid version-baby3.txt");
            }
        } else {
            if (global.dev) console.log("ERR3");
        }
        // if (response.status === 200) {
        //     let data = await response.text();
        //     // console.log("DATA3", data);
        //     let lines = data.split('\n');
        //     appState.baby3_check_version = lines.splice(0, 1);
        //     appState.baby3_check_text = lines.join('\n');
        //     console.log("VER3", appState.baby3_check_version);
        //     console.log("MSG3", appState.baby3_check_text);
        // } else {
        //     console.log("ERR3");
        // }
    };

    componentDidMount() {
        this.readVersionFiles();
    }

/*
    componentDidMount(){
        const s = loadPreferences();
        this.setState(
            {
                theme: s.theme || DEFAULT_THEME
                // consoleActive: s.midi_console || false,
                // consolePosition: s.midi_console_position || DEFAULT_CONSOLE_POSITION,
                // help: s.help || false,
                // helpPosition: s.help_position || DEFAULT_HELP_POSITION
            }
        );
    }
*/

    render() {

        if (global.dev) console.info(process.env.REACT_APP_NAME, process.env.REACT_APP_VERSION);

        const { dropZoneActive } = this.state;

        return (
            <Provider appState={appState}>
                <Fragment>
                    <Header toggleHelp={this.toggleHelp}/>
                    <Dropzone onDrop={this.onDrop}
                              onDragEnter={this.onDragEnter}
                              onDragLeave={this.onDragLeave}
                              noClick>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {dropZoneActive &&
                                <div style={dropOverlayStyle}>
                                    SysEx file drop...
                                </div>}
                                <MidiHandler />
                                <Information />
                                <Editor />
                            </div>
                        )}
                    </Dropzone>
                    {/*<DumpState />*/}
                </Fragment>
            </Provider>
        );
    }
}

export default App;
