import {ACTION, CMD_LOAD_BUTTON_CONFIG} from "./constants";

export const DEFAULT_HOLD_TIME = 10;        // 500 ms
export const DEFAULT_LONG_HOLD_TIME = 100;  // 5000 ms

// length is number of bytes excluding sysex header (1 byte), sys manufacturer (3 bytes) and sysex end (1 byte)
export const button_length = 22 - 1 - 3 - 1;

// offset 0 is first byte after manufacturer ID
export const button_offset = {
    command: 0,
    button_number: 1,
    hold_time: 2,
    long_hold_time: 3,
    msgs_tap: 4,            // Number of messages for TAP
    msgs_hold: 5,
    msgs_long_hold: 6,
    delay_tap: 7,           // Delay | Message Batch size TAP
    delay_hold: 8,
    delay_long_hold: 9,
    color_tap: 10,
    color_hold: 11,
    color_long_hold: 12
};

export function getDefaultButtonSysex(button_number) {
    const data = new Array(button_length).fill(0);
    data[button_offset.command] = CMD_LOAD_BUTTON_CONFIG;
    data[button_offset.button_number] = button_number;
    return data;
}

export function offsetDelay(action) {
    switch(action) {
        case ACTION.tap: return button_offset.delay_tap;
        case ACTION.hold: return button_offset.delay_hold;
        case ACTION.long_hold: return button_offset.delay_long_hold;
        default: console.error(`offsetDelay: invalid action: ${action}`);
    }
}

export function offsetColor(action) {
    switch(action) {
        case ACTION.tap: return button_offset.color_tap;
        case ACTION.hold: return button_offset.color_hold;
        case ACTION.long_hold: return button_offset.color_long_hold;
        default: console.error(`offsetColor: invalid action: ${action}`);
    }
}


export function humanHoldTime(time) {
    // if (time === 0) return "immediate action";
    return `${time * 50} ms`;
}

export function humanLongHoldTime(time) {
    return `${time * 50} ms`;
}

export function humanDelay(delay) {
    return `${delay * 5} ms`;
}

/**
 * BABY 1:
 *    0 Main FS
 *    1 MultiJack Tip / Exp
 *    2 MultiJack Ring
 * BABY 3:
 *    0 Right FS
 *    1 Center FS
 *    2 Left FS
 *    3 MultiJack 1 Tip / Exp
 *    4 MultiJack 1 Ring
 *    5 MultiJack 2 Tip / Exp
 *    6 MultiJack 2 Ring
 */
export const BABY1_BUTTONS = 3;
export const BABY3_BUTTONS = 7;
export const MAX_BUTTONS = Math.max(BABY1_BUTTONS, BABY3_BUTTONS);    // number of buttons; index will be 0..(max_buttons - 1)

export function getButtonsStruct() {
    let buttons = {};
    for (let i=0; i<MAX_BUTTONS; i++) {
        buttons[i] = getDefaultButtonSysex(i);
    }
    return buttons;
}

export function getButtonsDirtyStruct() {
    let buttons = {};
    for (let i=0; i<MAX_BUTTONS; i++) {
        buttons[i] = false;
    }
    return buttons;
}
