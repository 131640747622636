import React, {Component} from 'react';
import {appState} from "./state/State";
import {Provider} from "mobx-react";
import {getParameterByName, readFiles} from "./utils/utils";
import GlobalConfig from "./print/GlobalConfig";
import {fromHexString, hs} from "./utils/hexstring";
import Dropzone from "react-dropzone";
import Buttons from "./print/Buttons";
import {isSysexData} from "./model/sysex";
import './Print.css';
import {MODEL_BABY3} from "./model/constants";

const dropOverlayStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: '240px',
    background: 'rgba(0,0,0,0.4)',
    textAlign: 'center',
    color: '#fff',
    fontSize: '4rem'
};

class Print extends Component {

    state = {
        dropZoneActive: false
    };

    onDragEnter = () => {
        this.setState({dropZoneActive: true});
    };

    onDragLeave = () => {
        this.setState({dropZoneActive: false});
    };

    /**
     * Drop Zone handler
     * @param files
     */
    onDrop = async (files) => {
        this.setState({dropZoneActive: false});
        const bytes = await readFiles(files);
        if (global.dev) console.log("onDrop: bytes read", hs(bytes));
        appState.importSysexDump(bytes, true);
    };

    componentDidMount() {
        // let data = null;
        const s = getParameterByName("sysex");
        if (s) {
            // data = ;
            const data = new Uint8Array(fromHexString(decodeURI(s)));
            if (isSysexData(data)) {
                // bytes = data;
                appState.importSysexDump(data, true);
            } else {
                console.warn('The sysex parameter does not contain a valid patch');
            }
        }
        // console.log("componentDidMount", this.props.model);
        appState.setModel(this.props.model);
    }

    render() {

        // if (global.dev) console.info(process.env.REACT_APP_NAME, process.env.REACT_APP_VERSION);
        const {dropZoneActive} = this.state;

        // console.log("render", this.props.model);

        let now = new Date();
        let timestamp =
            now.getUTCFullYear() + "-" +
            ("0" + (now.getUTCMonth() + 1)).slice(-2) + "-" +
            ("0" + now.getUTCDate()).slice(-2) + "-" +
            ("0" + now.getUTCHours()).slice(-2) + "" +
            ("0" + now.getUTCMinutes()).slice(-2) + "" +
            ("0" + now.getUTCSeconds()).slice(-2);

        return (
            <Provider appState={appState}>
                <div>
                    <Dropzone onDrop={this.onDrop}
                              onDragEnter={this.onDragEnter}
                              onDragLeave={this.onDragLeave}
                              noClick>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {dropZoneActive &&
                                <div style={dropOverlayStyle}>
                                    Drop sysex file...
                                </div>}
                                {/*<div>*/}
                                    <div className="print-title" contentEditable="true">
                                        MIDI Baby{this.props.model === MODEL_BABY3 ? "3" : ""} - {timestamp}
                                    </div>
                                    <div className="print-title-help">You can edit this title. Just click inside.</div>
                                    <GlobalConfig />
                                    <Buttons />
                                {/*</div>*/}
                            </div>
                        )}
                    </Dropzone>
                </div>
            </Provider>
        );
    }
}

export default Print;
