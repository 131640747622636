import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import {MESSAGE_TYPE} from "../model/constants";
import {
    offset,
    offsetChannel,
    offsetController,
    offsetEndValue,
    offsetStartValue
} from "../model/preset";
import "./ActionEditor.css";
import "./ExpMessagesEditor.css";

const Message = ({msg_index, preset, updateCallback, deleteCallback}) => {
    return (
        <Fragment>
            <div>{msg_index+1}</div>
            <div>
                CC
            </div>
            <div>
                <select value={preset[offsetChannel(msg_index)]} onChange={(e) => updateCallback('channel', msg_index, e.target.value)}>
                    {Array.from(Array(16).keys()).map(i => <option key={i+1} value={i+1}>{i+1}</option>)}
                </select>
            </div>
            <div>
                <span className="edit-value-label">
                    <input type="text" size="4"
                           value={preset[offsetController(msg_index)]}
                           onChange={(e) => updateCallback('controller', msg_index, e.target.value)}
                           onBlur={(e) => updateCallback('controller', msg_index, e.target.value, true)} />
                </span>
            </div>
            <div>
                <span className="edit-value-label">
                <input type="text" size="4"
                       value={preset[offsetStartValue(msg_index)]}
                       onChange={(e) => updateCallback('startvalue', msg_index, e.target.value)}
                       onBlur={(e) => updateCallback('startvalue', msg_index, e.target.value, true)} />
                </span>
            </div>
            <div>
                <span className="edit-value-label">
                <input type="text" size="4"
                       value={preset[offsetEndValue(msg_index)]}
                       onChange={(e) => updateCallback('endvalue', msg_index, e.target.value)}
                       onBlur={(e) => updateCallback('endvalue', msg_index, e.target.value, true)} />
                </span>
            </div>
            <div>
                <button type="button" className="btn btn-small btn-danger" onClick={() => deleteCallback(msg_index)}>delete</button>
            </div>
        </Fragment>
    );
};

class ExpMessagesEditor extends React.Component {

    state = {
        refresh_messages: false
    };

    addMessage = () => {
        this.props.appState.incNumberOfMessages(this.props.button, this.props.action);
        // this.setParam(ACTION.exp, 'messagetype', this.props.appState.getNumberOfMessages(BUTTON.tip, ACTION.exp), MESSAGE_TYPE.CC_toggle);
        const N = this.props.appState.getNumberOfMessages(this.props.button, this.props.action) - 1;
        this.setParam(this.props.action, 'messagetype', N, MESSAGE_TYPE.CC_toggle.toString(10));
        this.setParam(this.props.action, 'channel', N, '1');
        this.setParam(this.props.action, 'startvalue', N, '0');
        this.setParam(this.props.action, 'endvalue', N, '127');
        this.props.appState.setButtonDirty(this.props.button);
        this.props.appState.setPresetDirty(this.props.button);
    };

    deleteMessage = (message_number) => {
        this.props.appState.deleteMessage(this.props.button, this.props.action, message_number);
        this.props.appState.setButtonDirty(this.props.button);
        this.props.appState.setPresetDirty(this.props.button);
    };

    moveMessage = (message_number, new_number) => {
        this.props.appState.moveMessage(this.props.button, this.props.action, message_number, new_number);
        this.props.appState.setPresetDirty(this.props.button);
    };

    setParam = (action, param, message_number, str_value, fix_value = false) => {

        // in MIDI value must be 0..127
        if (fix_value) {
            const v = str_value ? parseInt(str_value, 10) : 0;
            if (v > 127) {
                if (global.dev) console.log('value needs to be fixed', v);
                str_value = '127';
            } else if (v < 0) {
                if (global.dev) console.log('value needs to be fixed', v);
                str_value = '0';
            }
            if (global.dev) console.log('fixed value', str_value);
        }

        // this.props.appState.preset[this.props.button][action][offset(param, message_number)] = value.toString(10);
        this.props.appState.preset[this.props.button][action][offset(param, message_number)] = str_value;

        this.setState({refresh_messages: !this.state.refresh_messages});
        this.props.appState.setPresetDirty(this.props.button);
    };

    render() {

        const {button, action} = this.props;   // action is one of ACTION
        const S = this.props.appState;
        const N = S.getNumberOfMessages(button, action);

        return (
            <div className="edit-action">

                {N === 0 &&
                <div className="action-row">
                    There are no messages defined for this action.
                </div>}

                {N > 0 &&
                <div className="edit-grid exp">
                    <div className="h">#</div>
                    <div className="h">msg type</div>
                    <div className="h">channel</div>
                    <div className="h">controller</div>
                    <div className="h">toe up</div>
                    <div className="h">toe down</div>
                    <div/>
                    {Array.from(Array(N).keys()).map(i =>
                        <Message key={i} msg_index={i} preset={S.preset[button][action]}
                                 updateCallback={(param, message_number, str_value) => this.setParam(action, param, message_number, str_value)}
                                 deleteCallback={(message_number) => this.deleteMessage(message_number)}
                                 bool={this.state.refresh_messages} />
                    )}
                </div>}

                {N < 16 &&
                <div className="row aligned">
                    <button type="button" className="btn btn-x-small btn-success" onClick={this.addMessage}>Add message</button>
                    <div className="help-text left-space-double">You can define up to 16 messages per action.</div>
                </div>}

            </div>
        );
    }
}

export default inject('appState')(observer(ExpMessagesEditor));
