import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import {ACTION, COLOR_LABEL} from "../model/constants";
import {button_offset, humanDelay, humanHoldTime, humanLongHoldTime} from "../model/button";
import "./ButtonAction.css";
import ActionMessages from "./ActionMessages";

const SEND_ALL_MESSAGES_AT_ONCE = 0;
const SEND_MESSAGES_IN_BATCH = 1;
const SEND_MESSAGES_ONE_BY_ONE = 2;

class ButtonAction extends React.Component {

    render() {

        const { button, action } = this.props;

        const S = this.props.appState;
        const n = S.getNumberOfMessages(button, action);

        const hold = S.button[button][button_offset.hold_time];
        const long_hold = S.button[button][button_offset.long_hold_time];
        // if (global.dev) console.log("* button", button, action, hold, long_hold);

        const release_mode = hold === 1;

        let name;
        // let description;
        switch (action) {
            case ACTION.tap:
                name = 'PRESS';
                // description = 'Messages are sent immediately on press of the footswitch.';
                break;
            case ACTION.hold:
                if (release_mode) {
                    name = 'RELEASE';   //TODO: constants for strings
                    // description = 'Messages are sent on release of the footswitch.';
                } else {
                    name = 'HOLD';
                    // description = 'Messages are sent after the configured delay.';
                }
                break;
            case ACTION.long_hold:
                name = 'LONG HOLD';
                // description = 'Messages are sent after the configured delay.';
                break;
            default:
                console.error("ButtonAction: invalid action:", action);
                name = 'TAP';
                // description = '';
                break;
        }

        // let n_messages = '';
        // switch (n) {
        //     case 0 : n_messages = 'No message defined for this action.'; break;
        //     case 1 : n_messages = 'One message will be sent for this action.'; break;
        //     default: n_messages = `${n} messages will be sent for this action.`;
        // }

        const dly = S.getDelayBetweenMessages(button, action);

        let current_send_mode;
        if (dly === 0) {
            current_send_mode = SEND_ALL_MESSAGES_AT_ONCE;
        } else if (dly <= 16) {
            current_send_mode = SEND_MESSAGES_IN_BATCH;
        } else {
            current_send_mode = SEND_MESSAGES_ONE_BY_ONE;
        }

        let send_behavior = '';
        if (n > 0) {
            if (current_send_mode === SEND_ALL_MESSAGES_AT_ONCE) {
                send_behavior = "Send all messages at once.";
            } else if (current_send_mode === SEND_MESSAGES_IN_BATCH) {
                send_behavior = `Send messages in batch with ${dly} messages per action.`;
            } else if (current_send_mode === SEND_MESSAGES_ONE_BY_ONE) {
                send_behavior = `Send messages one by one with a delay of ${humanDelay(dly)} between each message.`;
            }
        }

        const action_enabled = true;
            // (action === ACTION.tap) ||
            // (action === ACTION.hold && hold > 0) ||
            // (action === ACTION.long_hold && long_hold > 0);

        // const border_class = action_enabled ? `color-${S.button[button][offsetColor(action)]}` : 'ignored';

        return (
            <Fragment>

                <div className="button-action-name">{name.split(' ').map(s => <div key={name}>{s}</div>)}</div>

                <div className="button-action-action">

                    {!release_mode && action === ACTION.hold && action_enabled &&
                    <div className="action-label">Hold delay: {humanHoldTime(hold)}</div>}

                    {action === ACTION.long_hold && action_enabled &&
                    <div className="action-label">Long-hold delay: {humanLongHoldTime(long_hold)}</div>}

                    <div className="action-label">LED color: {COLOR_LABEL[S.getColor(button, action)].toLowerCase()}</div>

                    {n === 0 && <div>No message defined for this action.</div>}
                    
                    <div>
                        {send_behavior}
                    </div>

                    <div className="Xaction-row">
                        {action_enabled && <ActionMessages button={button} action={action} />}
                    </div>

                </div>

            </Fragment>

        );

/*
        return (
            <div className={`button-action ${border_class} ${this.props.className ? this.props.className : ''}`}>

                <div className={`action-grid-${action}`}>

                    <div className={`action-name ${border_class}`}>
                        <div className="action-name-name">{name}</div>
                        <div className="instruction">{description}</div>
                    </div>

                    {action_enabled &&
                    <Fragment>
                        <div className="action-label">LED color:</div>
                        <div className="Xaction-row">
                            <div>{COLOR_LABEL[S.getColor(button, action)]}</div>
                        </div>

                        {(n === 0 || n > 1) &&
                        <Fragment>
                            <div className="action-label">Messages:</div>
                            <div className="Xaction-row">

                                {n === 0 &&
                                <div className="Xaction-row">
                                    {n_messages}
                                </div>
                                }

                                {n > 0 &&
                                <Fragment>
                                    <div>send</div>

                                    {current_send_mode === SEND_ALL_MESSAGES_AT_ONCE &&
                                    <Fragment>
                                        <div>all messages at once</div>
                                    </Fragment>}

                                    {current_send_mode === SEND_MESSAGES_IN_BATCH &&
                                    <Fragment>
                                        <div>messages in batch</div>
                                        <div>with</div>
                                        <div>
                                            {`${dly}`}
                                        </div>
                                        <div>messages per action</div>
                                    </Fragment>}

                                    {current_send_mode === SEND_MESSAGES_ONE_BY_ONE &&
                                    <Fragment>
                                        <div>messages one by one</div>
                                        <div>with a delay of</div>
                                        <div>{humanDelay(dly)}</div>
                                        <div>between each message</div>
                                    </Fragment>}
                                </Fragment>}
                            </div>
                        </Fragment>}

                        {n === 1 &&
                        <Fragment>
                            <div className="action-label">Message:</div>
                            <div className="Xaction-row">
                                {action_enabled && <ActionSummary button={button} action={action} />}
                            </div>
                        </Fragment>
                        }

                    </Fragment>
                    }

                    {(n === 0 || n > 1) &&
                    <Fragment>
                        <div>{/!* empty cell *!/}</div>
                        {action_enabled && <ActionSummary button={button} action={action} />}
                    </Fragment>}

                </div>

            </div>
        );
*/
    }
}

export default inject('appState')(observer(ButtonAction));
