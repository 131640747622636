import React from "react";
import {inject, observer} from "mobx-react";
import {
    ACTION, SUB_CONFIG_SAVE_ENABLED,
    SYSEX_SIGNATURE
} from "../model/constants";
import ButtonAction from "./ButtonAction";
import {outputById} from "../utils/ports";
import {byteArray2stringArray, wait} from "../utils/utils";
import {button_offset, DEFAULT_HOLD_TIME, DEFAULT_LONG_HOLD_TIME, getDefaultButtonSysex} from "../model/button";
import {hs} from "../utils/hexstring";
import {getDefaultPresetSysex, preset_offset} from "../model/preset";
import {PRESETS} from "../presets/presets";
import {WAIT_BETWEEN_MESSAGES} from "../model";
import {DIRECTION_WRITE} from "../state/State";
import "./Button.css";

const BEHAVIOR_IMMEDIATE = 0;
const BEHAVIOR_FALL_RISE = 1;
const BEHAVIOR_DELAY = 2;

class Button extends React.Component {

    state = {
        action: null,  // action being edited; value is one of ACTION
        quick_config: '',
        panel_open: true,
        save_help: false
    };

    togglePanel = () => {
        this.setState({panel_open: !this.state.panel_open});
    };

    getBehavior = () => {
        const t = this.props.appState.button[this.props.button][button_offset.hold_time];
        if (t === 0) {
            return BEHAVIOR_IMMEDIATE;
        } else if (t === 1) {
            return BEHAVIOR_FALL_RISE
        } else {
            return BEHAVIOR_DELAY;
        }
    };

    setBehavior = (e) => {

        const behavior = parseInt(e.target.value, 10);

        const { button } = this.props;

        //
        // - 0 is instant action, no hold or long-hold will be sent.
        // - 1 sends tap on the falling edge of the button and hold on the rising edge. I think long hold may still be enabled in this mode but I will confirm.
        // - Anything greater than 1 is just the delay * 50ms.
        //

        switch (behavior) {
            case BEHAVIOR_IMMEDIATE:
                this.props.appState.button[button][button_offset.hold_time] = 0;
                this.props.appState.button[button][button_offset.long_hold_time] = 0;
                this.props.appState.button[button][button_offset.msgs_hold] = 0;
                this.props.appState.button[button][button_offset.msgs_long_hold] = 0;
                this.props.appState.setButtonDirty(button);
                break;
            case BEHAVIOR_FALL_RISE:
                this.props.appState.button[button][button_offset.hold_time] = 1;        // THIS DEFINE THE BEHAVIOR
                this.props.appState.button[button][button_offset.long_hold_time] = 0;
                this.props.appState.button[button][button_offset.msgs_long_hold] = 0;
                this.props.appState.setButtonDirty(button);
                break;
            case BEHAVIOR_DELAY:
                if (this.props.appState.button[button][button_offset.hold_time] < 2) {                //TODO: review logic. If we arrive here, the time must already be at zero
                    if (global.dev) console.log("set default hold");
                    this.props.appState.button[button][button_offset.hold_time] = DEFAULT_HOLD_TIME;
                }
                if (this.props.appState.button[button][button_offset.long_hold_time] === 0) {
                    if (global.dev) console.log("set default long hold");
                    this.props.appState.button[button][button_offset.long_hold_time] = DEFAULT_LONG_HOLD_TIME;
                }
                this.props.appState.setButtonDirty(button);
                break;
            default:
                console.error("Button: invalid behavior value: ", behavior)
        }
    };

    save = (data) => {

        const out = outputById(this.props.appState.midi.output);
        if (!out) {
            console.warn(`save: output ${this.props.appState.midi.output} not found`);
            return;
        }
        if (!data || data.length < 1) {
            console.warn(`save: data is empty`);
            return;
        }

        if (global.dev) console.log(`save: send ${data.length} + 5 bytes`, hs(data));

        out.sendSysex(SYSEX_SIGNATURE, data);

        //TODO: read back and check that the save has been successful. If yes, clear the dirty flag.

    };

    saveButton = () => {
        this.save(this.props.appState.button[this.props.button]);
        this.props.appState.write_progress++;
        this.props.appState.setButtonClean(this.props.button);
    };

    savePreset = (action) => {  //FIXME: add button param
        this.save(this.props.appState.presetBytes(this.props.button, action));
        this.props.appState.write_progress++;
        this.props.appState.setPresetClean(this.props.button);
    };

    saveButtonAndPreset = async () => {

        this.props.appState.resetExpected(4);
        this.props.appState.direction = DIRECTION_WRITE;

        // the button number is in the data
        this.saveButton();

        await wait(WAIT_BETWEEN_MESSAGES);
        this.savePreset(ACTION.tap);

        await wait(WAIT_BETWEEN_MESSAGES);
        this.savePreset(ACTION.hold);

        await wait(WAIT_BETWEEN_MESSAGES);
        this.savePreset(ACTION.long_hold);

        //TODO: re-read to check
    };

    showSaveHelp = () => {
        this.setState({save_help: true});
    };

    hideSaveHelp = () => {
        this.setState({save_help: false});
    };

    quickConfig = (preset) => {

        if (global.dev) console.log("quickConfig", preset);

        this.setState({quick_config: preset});

        if (!preset) return;

        const S = this.props.appState;
        const p = PRESETS[preset];
        const button = this.props.button;

        if (p.global) {
            if (global.dev) console.log("quickConfig: global");
            S.global = p.global;
            S.setGlobalDirty();
        }

        if (p.button) {
            if (Object.keys(p.button).length > 1) {
                console.warn("quickConfig: button preset for more than one button; not supported in this version")
            } else {
                if (p.button[0] === null) {
                    if (global.dev) console.log(`quickConfig: clear button[0]`);
                    S.button[button] = getDefaultButtonSysex(button);
                    S.setButtonDirty(button);
                } else if (p.button[0]) {
                    if (global.dev) console.log(`quickConfig: setup button[0]`);
                    S.button[button] = p.button[0];
                    S.button[button][button_offset.button_number] = button;
                    S.setButtonDirty(button);
                }
            }
        }

        if (p.preset) {
            if (Object.keys(p.preset).length > 1) {
                console.warn("quickConfig: preset for more than one button; not supported in this version")
            } else {
                for (let action=0; action < 3; action++) {
                    // if (global.dev) console.log("quickConfig: preset: action loop; action=", action);
                    if (p.preset[0][action] === null) {
                        if (global.dev) console.log(`quickConfig: clear preset[${button}][${action}]`);
                        S.preset[button][action] = byteArray2stringArray(getDefaultPresetSysex(button, action));
                        S.setPresetDirty(button);
                    } else if (p.preset[0][action]) {
                        if (global.dev) console.log(`quickConfig: setup preset[${button}][${action}]`);
                        S.preset[button][action] = byteArray2stringArray(p.preset[0][action]);
                        S.preset[button][action][preset_offset.preset_number] = button.toString();
                        S.setPresetDirty(button);
                    }
                }
            }
        }
    };

    render() {

        // if (global.dev) console.log(`%cButton.render()`, "color: green; font-weight: bold");

        const {button, name} = this.props;
        const panel_open = this.state.panel_open;
        const dirty = this.props.appState.isButtonDirty(button) || this.props.appState.isPresetDirty(button);
        const behavior = this.getBehavior();

        // const save_button_label = `Save ${BUTTON_NAME[button]} config in device`;

        return (
            <div className={`button-editor panel-wrapper ${panel_open ? '' : 'panel-closed'}`}>

                {SUB_CONFIG_SAVE_ENABLED && dirty && this.props.appState.connected &&
                <div className="button-save-button" >
                    {this.state.save_help && <div className="help">
                        Write the <span className="b">{name}</span> configuration in the MIDI Baby.<br />
                        Tip: use the <span className="b">Write Device</span> button to save <span className="b">everything</span> at once.
                    </div>}
                    <button type="button" className="btn btn-danger" onClick={this.saveButtonAndPreset} onMouseOver={this.showSaveHelp} onMouseOut={this.hideSaveHelp}>Save</button>
                </div>
                }

                <div className="panel-title">
                    <div className="panel-toggle" onClick={this.togglePanel} title={panel_open ? 'Click to hide' : 'Click to show'}>
                        {name}
                    </div>
                    {false && SUB_CONFIG_SAVE_ENABLED && dirty && this.props.appState.connected && <button type="button" className="btn btn-large btn-danger btn-white-border" onClick={this.saveButtonAndPreset}>Save</button>}
                    {false && !SUB_CONFIG_SAVE_ENABLED && dirty && this.props.appState.connected &&
                    <div className="save-reminder">Config modified. Click <strong>Write device</strong> button to save your modifications.</div>
                    }
                </div>

                <div className="panel-content content-wrapper">
                    <div className="button-global">

                        <div>
                            <label onClick={(e) => e.stopPropagation()} className={behavior === BEHAVIOR_IMMEDIATE ? 'checked' : ''}>
                                <input type="radio" value={BEHAVIOR_IMMEDIATE} checked={behavior === BEHAVIOR_IMMEDIATE} onChange={this.setBehavior} />
                                Immediate action on PRESS.
                            </label>
                        </div>

                        <div>
                            <label onClick={(e) => e.stopPropagation()} className={behavior === BEHAVIOR_FALL_RISE ? 'checked' : ''}>
                                <input type="radio" value={BEHAVIOR_FALL_RISE} checked={behavior === BEHAVIOR_FALL_RISE} onChange={this.setBehavior} />
                                Send PRESS messages on falling edge, RELEASE messages on rising edge.
                            </label>
                        </div>

                        <div>
                            <label onClick={(e) => e.stopPropagation()}  className={behavior === BEHAVIOR_DELAY ? 'checked' : ''}>
                                <input type="radio" value={BEHAVIOR_DELAY} checked={behavior === BEHAVIOR_DELAY} onChange={this.setBehavior} />
                                Send PRESS messages on falling edge, HOLD and LONG-HOLD messages after the configured delays.
                            </label>
                        </div>

{/*
                        <div className="label">Quick config:</div>
                        <div>
                            <label>
                                <select value={this.state.quick_config} onChange={(e) => this.quickConfig(e.target.value)}>
                                    <option value="">-</option>
                                    {Object.keys(PRESETS).map(
                                        (v, i) => {
                                            return <option key={i} value={v}>{PRESETS[v].name}</option>
                                        })}
                                </select>
                            </label>
                        </div>
                        {this.state.quick_config &&
                        <Fragment>
                        <div/>
                        <div className="instruction">
                            {PRESETS[this.state.quick_config].description}
                        </div>
                        </Fragment>}
*/}

                    </div>
                </div>

                <div className="panel-content content-wrapper">
                    <div className="button-actions">
                        <ButtonAction button={button} action={ACTION.tap} setDirty={this.setDirty} enabled={true} />
                        {behavior !== BEHAVIOR_IMMEDIATE && <ButtonAction button={button} action={ACTION.hold} setDirty={this.setDirty} enabled={behavior !== BEHAVIOR_IMMEDIATE} />}
                        {behavior === BEHAVIOR_DELAY && <ButtonAction button={button} action={ACTION.long_hold} className="last" setDirty={this.setDirty} enabled={behavior === BEHAVIOR_DELAY} />}
                    </div>
                </div>

            </div>
        );
    }
}

export default inject('appState')(observer(Button));
