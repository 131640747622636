import {isSysexData} from "../model/sysex";
import {hs} from "./hexstring";

export const wait = ms => new Promise(r => setTimeout(r, ms));

export function inputIntValue(s) {
    if (s === undefined || s === null || s === '') return 0;
    return parseInt(s, 10) % 256;
}

export function byteArray2stringArray(bytes) {
    return bytes.map(b => {
        if (b === undefined || b === null) {
            return '';
        } else {
            return b.toString()
        }
    });
}

// No check is done to valid the value's range (0..127)
export function stringArray2byteArray(strings) {
    // if (global.dev) console.log("stringArray2byteArray", strings);
    return strings.map(s => inputIntValue(s));
}

const MAX_FILE_SIZE = 4 * 1024;

export async function readFiles(files) {

    if (global.dev) console.log("readFiles", files);
    let bytes = null;
    await Promise.all(files.map(
        async file => {
            if (file.size > MAX_FILE_SIZE) {
                console.warn(`${file.name}: file too big, ${file.size}`);
            } else {
                const data = new Uint8Array(await new Response(file).arrayBuffer());
                if (isSysexData(data)) {
                    if (bytes === null) {
                        bytes = data;
                    } else {
                        // merge sysex bytes
                        const a = new Uint8Array(bytes.length + data.length);
                        a.set(bytes);
                        a.set(data, bytes.length);
                        bytes = a;
                    }
                    if (global.dev) console.log(`readFiles: patch file loaded: ${file.name}, ${bytes.length} bytes`);
                } else {
                    console.warn(`The file ${file.name} does not contain a patch (is not a binary sysex file)`);
                }
                // non sysex files are ignored
            }
            // too big files are ignored
        }
    ));
    if (global.dev) console.log("readFiles: return", hs(bytes));
    return bytes;
}

export function getParameterByName(name) {
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}










// export function setFoo() {
//     appState.foo = "Dummy!";
//     appState.global[global_offset.multijack]++;
// }

