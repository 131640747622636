import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import {
    offsetChannel,
    offsetController, offsetEndValue,
    offsetMessageType, offsetStartValue
} from "../model/preset";
import {
    MSG_WITH_CHANNEL
} from "../model/constants";
import "./ActionSummary.css"

const Message = ({msg_index, preset}) => {

    const msg_type = parseInt(preset[offsetMessageType(msg_index)], 10);

    let channel;
    if (MSG_WITH_CHANNEL.includes(msg_type)) {
        channel = <div>channel {parseInt(preset[offsetChannel(msg_index)], 10)}</div>;
    } else {
        channel = <div></div>;
    }

    const controller = parseInt(preset[offsetController(msg_index)], 10);
    const start = parseInt(preset[offsetStartValue(msg_index)], 10);
    const end = parseInt(preset[offsetEndValue(msg_index)], 10);

    return (
        <Fragment>
            <div className="msg-number">{msg_index+1}:</div>
            <div className="msg-type">CC</div>
            <div className="msg-channel">
                {channel}
            </div>
            <div className="msg-description">
                <div>Send CC{controller} from {start} to {end}.</div>
            </div>
        </Fragment>
    );
};

class ExpMessagesSummary extends React.Component {

    render() {

        const { button, action } = this.props;
        const S = this.props.appState;
        const N = S.getNumberOfMessages(button, action);

        return (
            <div className="messages-summary exp">
                {N === 0 &&
                <div className="action-row">
                    There are no messages defined for this action.
                </div>}
                {Array.from(Array(N).keys()).map(i =>
                    <Message key={i} msg_index={i} preset={S.preset[button][action]} />
                )}
            </div>
        );
    }
}

export default inject('appState')(observer(ExpMessagesSummary));
