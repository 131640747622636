
export const SUB_CONFIG_SAVE_ENABLED = true;


export const MODEL_UNKNOWN = 0x0b0b;
export const MODEL_BABY1 = 0x0b0b;
export const MODEL_BABY3 = 0x0b03;

export const MODEL_LABEL = {
    [MODEL_UNKNOWN]: "Unknown model",
    [MODEL_BABY1]: "MIDI Baby",
    [MODEL_BABY3]: "MIDI Baby3"
};

export const SYSEX_SIGNATURE = [0x00, 0x02, 0x17];

export const SYSEX_START = 0xF0;
export const SYSEX_END = 0xF7;

export const CMD_LOAD_BUTTON_CONFIG = 0x0C;       // Load in button config
export const CMD_LOAD_GLOBAL_CONFIG = 0x0D;       // Load in configuration data (globals)
export const CMD_LOAD_PRESET = 0x0F;	          // Load in preset
export const CMD_REQUEST_BUTTON_CONFIG = 0x1C;    // Request button n config
export const CMD_REQUEST_GLOBAL_CONFIG = 0x1D;    // Request configuration date (globals) returns sysex message
export const CMD_REQUEST_PRESET = 0x1F;	          // Request preset data; returns sysex message
export const CMD_DISABLE_CLOCK = 0x0B; 	          // Turn off MIDI clock temporarily

export const BUTTON = {
    footswitch: 0,
    tip: 1,
    ring: 2
    // exp: 1          // EXP uses TIP also
};

/*
export const BUTTON_NAME = {
    0: "Main footswitch",
    1: "Ext footswitch - Tip",
    2: "Ext footswitch - Ring"
};
*/

export const MULTIJACK = {
    tip: 0,     // symbol : value
    ring: 1,
    both: 2,
    exp: 3,
    fs: 4,
    io: 5
};

export const MULTIJACK_LABEL = {
    0: "MIDI Tip",   // value : label
    1: "MIDI Ring",
    2: "MIDI Tip+Ring",
    3: "Expression pedal",
    4: "External footswitch",
    5: "MIDI I/O"
};

export const ACTION = {
    tap: 0x00,
    hold: 0x01,
    long_hold: 0x02,
    exp: 0x00,          // same as tap  confirm?
    release: 0x01       // same as hold
};

export const MIDI_THRU = {
    din: 0,
    multijack: 1,
    usb: 2
};

export const MIDI_THRU_LABEL = {
    0: "DIN",
    1: "MULTIJACK",
    2: "USB"
};

export const MIDI_CLOCK = {
    off: 0,
    on: 1
};

export const MIDI_CLOCK_LABEL = {
    0: "Off",
    1: "On"
};

export const USB_FUNCTION = {
    midi: 0,
    host: 1
};

export const USB_FUNCTION_LABEL = {
    0: "MIDI",
    1: "Host"
};

export const MESSAGE_TYPE = {
    PC: 0x00,	        // Send a program change. If step value > 0, each press of the button will increment the counter and send the next PC
    CC_toggle: 0x01,    // Toggle a MIDI CC.  Toggles between high and low.  If step value > 0, counter value increases until rollover
    CC_return: 0x03,	//
    note: 0x02,	        // Send a MIDI note.  HighVal is velocity, delay determines how long before note off is sent.  Delay time = 5ms + (delay * 5)
    note_return: 0x04,	//
    strymon_bank: 0x05,	//
    batch_count_dec: 0x20,// decrement batch counter
    batch_dec_fire: 0x21, // decrement batch and fire action
    batch_count_inc: 0x22,// increment batch counter
    batch_inc_fire: 0x23, // increment batch counter and fire action
    batch_count_set: 0x24,// set batch counter to a number
    batch_set_fire: 0x25, // set batch to number and fire action
    // channel_all: 0x40,	// Change the channel of all actions on this button
    // invert_dir: 0x41,	// Invert the step direction of all actions on this button
    set_tempo: 0x50,	// Set the tempo of the MIDI clock, if clock is enabled
    start_clock: 0x51,	// Start clock and send MIDI START message, if clock is enabled
    stop_clock: 0x52,	// Stop clock and send MIDI STOP message, if clock is enabled
    toggle_clock: 0x53,	// Toggle clock between START and STOP states, and send the relevant message, if clock is enabled
    no_action: 0x7F	    // No action - do nothing
};

export const MESSAGE_TYPE_LABEL = {
    0x00: "Program Change",	    // Send a program change. If step value > 0, each press of the button will increment the counter and send the next PC

    0x01: "CC Toggle",      // Toggle a MIDI CC.  Toggles between high and low.  If step value > 0, counter value increases until rollover
    0x03: "CC Return",      //

    0x02: "Note",	        // Send a MIDI note.  HighVal is velocity, delay determines how long before note off is sent.  Delay time = 5ms + (delay * 5)
    0x04: "Note Return",    //

    0x05: "Strymon Bank",   //
    // 0x40: "Change channel",	// Change the channel of all actions on this button
    // 0x41: "Invert direction",	// Invert the step direction of all actions on this button

    0x50: "Set tempo",	    // Set the tempo of the MIDI clock, if clock is enabled
    0x51: "Start clock",	// Start clock and send MIDI START message, if clock is enabled
    0x52: "Stop clock",	    // Stop clock and send MIDI STOP message, if clock is enabled
    0x53: "Toggle clock",	// Toggle clock between START and STOP states, and send the relevant message, if clock is enabled

    0x20: "Dec batch counter",// Decrement batch counter
    0x21: "Dec batch counter and fire action", // Decrement batch and fire action
    0x22: "Inc batch counter",// Increment batch counter
    0x23: "Inc batch counter and fire action", // Increment batch counter and fire action
    0x24: "Set batch counter",// Set batch counter to a number
    0x25: "Set batch counter and fire action", // Set batch to number and fire action

    0x7F: "Do nothing"	    // No action - do nothing
};

/*
export const MESSAGE_TYPE_LABEL_SHORT = {
    0x00: "PC",	            // Send a program change. If step value > 0, each press of the button will increment the counter and send the next PC
    0x01: "CC Toggle",      // Toggle a MIDI CC.  Toggles between high and low.  If step value > 0, counter value increases until rollover
    0x02: "Note",	        // Send a MIDI note.  HighVal is velocity, delay determines how long before note off is sent.  Delay time = 5ms + (delay * 5)
    0x03: "CC Return",      //
    0x04: "Note Return",    //
    0x05: "Strymon Bank",   //
    // 0x40: "Chg ch",	        // Change the channel of all actions on this button             //TODO: still used?
    // 0x41: "Inv. dir.",	    // Invert the step direction of all actions on this button      //TODO: still used?
    0x50: "Set tempo",	    // Set the tempo of the MIDI clock, if clock is enabled
    0x51: "Start clock",	// Start clock and send MIDI START message, if clock is enabled
    0x52: "Stop clock",	    // Stop clock and send MIDI STOP message, if clock is enabled
    0x53: "Toggle clock",	// Toggle clock between START and STOP states, and send the relevant message, if clock is enabled
    0x7F: "Do nothing"	    // No action - do nothing
};
*/

export const BATCH_COUNTER_LABEL_BABY = [
    "Main footswitch Press",     // 0
    "Main footswitch Hold",     // 1
    "Main footswitch Long-Hold",     // 2
    "Multijack Tip Press",     // 3
    "Multijack Tip Hold",     // 4
    "Multijack Tip Long-Hold",     // 5
    "Multijack Ring Press",     // 6
    "Multijack Ring Hold",     // 7
    "Multijack Ring Long-Hold"     // 8
];

export const BATCH_COUNTER_LABEL_BABY3 = [
    "Right footswitch Press",     // 0
    "Right footswitch Hold",     // 1
    "Right footswitch Long-Hold",     // 2
    "Center footswitch Press",     // 3
    "Center footswitch Hold",     // 4
    "Center footswitch Long-Hold",     // 5
    "Left footswitch Press",     // 6
    "Left footswitch Hold",     // 7
    "Left footswitch Long-Hold",     // 8
    "Left Multijack Tip Press",     // 9
    "Left Multijack Tip Hold",     // 10
    "Left Multijack Tip Long-Hold",     // 11
    "Left Multijack Ring Press",     // 12
    "Left Multijack Ring Hold",     // 13
    "Left Multijack Ring Long-Hold",     // 14
    "Right Multijack Tip Press",     // 15
    "Right Multijack Tip Hold",     // 16
    "Right Multijack Tip Long-Hold",     // 17
    "Right Multijack Ring Press",     // 18
    "Right Multijack Ring Hold",     // 19
    "Right Multijack Ring Long-Hold"     // 20
];

export const MSG_WITH_CHANNEL = [0x00, 0x01, 0x02, 0x03, 0x04, 0x05];


export const BATCH_MSG = [0x20, 0x21, 0x22, 0x23, 0x24, 0x25];



/*
export const MESSAGE_TYPE_AUG = {   // MSB = extra type, LSB = stored type
    PC_END: 0x0100,
    PC_STEP: 0x0200,
    PC_COUNTER: 0x0300,
    // PC: 0x00,	        // Send a program change. If step value > 0, each press of the button will increment the counter and send the next PC
    CC_toggle: 0x01,    // Toggle a MIDI CC.  Toggles between high and low.  If step value > 0, counter value increases until rollover
    CC_return: 0x03,	//
    note: 0x02,	        // Send a MIDI note.  HighVal is velocity, delay determines how long before note off is sent.  Delay time = 5ms + (delay * 5)
    note_return: 0x04,	//
    strymon_bank: 0x05,	//
    // channel_all: 0x40,	// Change the channel of all actions on this button
    // invert_dir: 0x41,	// Invert the step direction of all actions on this button
    set_tempo: 0x50,	// Set the tempo of the MIDI clock, if clock is enabled
    start_clock: 0x51,	// Start clock and send MIDI START message, if clock is enabled
    stop_clock: 0x52,	// Stop clock and send MIDI STOP message, if clock is enabled
    toggle_clock: 0x53,	// Toggle clock between START and STOP states, and send the relevant message, if clock is enabled
    no_action: 0x7F	    // No action - do nothing
};
*/



export const COLOR_LABEL = {
    0: "off",
    1: "red",
    2: "green",
    3: "blue",
    4: "orange",
    5: "yellow",
    6: "cyan",
    7: "violet",
    8: "white"
};
