import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Print from "./Print";
import * as serviceWorker from './serviceWorker';
import './themes.css';
import './index.css';
// import './theme.css';
import './buttons.css';
import './sliders.css';
import HelpPopup from "./view/HelpPopup";
import {MODEL_UNKNOWN} from "./model/constants";

// noinspection JSUndefinedPropertyAssignment
global.dev = process.env.NODE_ENV === 'development';
global.trace = false;   //  process.env.NODE_ENV === 'development';

function getUrlParams() {
    let params = {};
    window.location.href.slice(window.location.href.indexOf('?') + 1).split('&').map(hash => {
        let [key, value] = hash.split('=');
        params[key] = decodeURIComponent(value);
        return null;
    });
    return params;
}


// document.body.className = "print";
// ReactDOM.render(<Print />, document.getElementById('root'));

const urlParams  = getUrlParams();
const print = urlParams["print"] && ["1", "y", "yes"].includes(urlParams["print"].toLowerCase());
const model = urlParams["model"] ? parseInt(urlParams["model"], 16) : MODEL_UNKNOWN;
const help = urlParams["help"]; // && ["1", "y", "yes"].includes(urlParams["help"].toLowerCase());
const midi = urlParams["midi"] && ["1", "y", "yes"].includes(urlParams["midi"].toLowerCase());
const dbg = urlParams["dbg"]; // && ["1", "y", "yes"].includes(urlParams["help"].toLowerCase());

// let model = null;
// switch (urlParams["model"] ? urlParams["model"].toLowerCase() : "") {
//     case "baby1" : model = MODEL_BABY1; break;
//     case "baby3" : model = MODEL_BABY3; break;
//     default: model = MODEL_BABY1; break;
// }

if (dbg) {
    global.dev = true;
}

if (print) {
    document.body.className = "print";
    ReactDOM.render(<Print model={model} />, document.getElementById('root'));
} else if (help) {
    document.body.className = "popup";
    ReactDOM.render(<HelpPopup />, document.getElementById('root'));
} else if (midi) {
    document.body.className = "popup";
    ReactDOM.render(<Print />, document.getElementById('root'));
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}
/*
switch (urlParams["print"]) {
    case "1":
    case "Y":
    case "y":
    case "yes":
    case "YES":
        document.body.className = "print";
        ReactDOM.render(<Print />, document.getElementById('root'));
        break;
    case undefined:
    default:
        ReactDOM.render(<App />, document.getElementById('root'));
        break;
}
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
