import {ACTION, BUTTON, MULTIJACK} from "../model/constants";

// DATA="00 02 17 0C ${BUTTON} ${HOLD_TIME} 00 01 00 00 00 00 00 ${COLOR} 00 00 00 00 00 00"

// #     1  2  3  4  5  6  7  8  9  10 11 12 13 14 15 16          20    22    24
// DATA="00 02 17 0f 00 00 01 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 10 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 01 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 7F 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 7F 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00 00"
// #                 |  |  |actions                                        |controller                                     |channel                                        |start value                                    |end value                                      |step                                           |counter                                        |spare
// #                 |  |action
// #                 |number


export const PRESETS = {
    boss: {
        name: 'Boss',
    },
    chasebliss: {
        name: 'Chase Bliss',
    },
    eventide: {
        name: 'Eventide',
    },
    line6: {
        name: 'Line 6',
    },
    meris: {
        name: 'Meris',
    },
    pigtronix: {
        name: 'Pigtronix',
    },
    strymon: {
        name: 'Strymon',
        description: 'Send BANK UP on PRESS. Send BANK DOWN on RELEASE.',
        global: [0x0D, MULTIJACK.tip, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        button: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: [0x0C, /*BUTTON*/ 0, /*HOLD_TIME*/ 20, /*LONG HOLD */ 0, /* NUM TAP */ 1, /* NUM HOLD */ 1, 0, 0, 0, 0, /*color*/ 7, 0, 0, 0, 0, 0, 0]
            // [BUTTON.tip]: null,
            // [BUTTON.ring]: null
        },
        preset: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: {
                [ACTION.tap]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.tap,     /* Preset Action */
                    0x05,0,   0,     0,  0,  0,  0,    0,    0,   0, 0, 0, 0, 0, 0, 0,   /* message types */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* controllers */
                    1,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* channels */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* start values */
                    1,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* end values */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* steps */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* counters */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* spares */
                ],
                [ACTION.hold]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.tap,     /* Preset Action */
                    0x05,0,   0,     0,  0,  0,  0,    0,    0,   0, 0, 0, 0, 0, 0, 0,   /* message types */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* controllers */
                    1,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* channels */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* start values */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* end values */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* steps */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* counters */
                    0,   0,   0,     0,  0,  0,  0,    0,     0,  0, 0,    0,    0,    0,    0,    0,   /* spares */
                ],
            }
        }
    },
    zoom: {
        name: 'Zoom',
    },
    test2: {
        name: 'TEST: all messages types',
        description: 'Description of the quick setup.',
        button: {
            [BUTTON.footswitch]: [0x0C, /*BUTTON*/ 0, /*HOLD_TIME*/ 0, /*LONG HOLD */ 0, /* NUM TAP */ 16, /* NUM HOLD */ 0, 0, 0, 0, 0, /*color*/ 4, 5, 6, 0, 0, 0, 0],
        },
        preset: {
            [BUTTON.footswitch]: {
                [ACTION.tap]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.tap,     /* Preset Action */
                    0,   0,   0,     1,  1,  1,  3,    2,    4,   5, 5, 0x50, 0x51, 0x52, 0x53, 0x7f,   /* message types */
                    1,   0,   0,    10, 10, 10, 60,   60,   72,   0, 0,    0,    0,    0,    0,    0,   /* controllers */
                    1,   1,   1,     2,  2,  2,  3,    4,     5,  6, 6,    7,    8,    9,   10,   11,   /* channels */
                    0,   1,   1,     0,  2,  1,  0,   60,   127,  0, 0,    0,    0,    0,    0,    0,   /* start values */
                    0,   1,  10,     0,  2, 10,  10,  127,    0,  0, 1,    0,    0,    0,    0,    0,   /* end values */
                    0,   1,   1,     0,  1,  1,   1,   1,    10,  0, 0,    0,    0,    0,    0,    0,   /* steps */
                    0,   0,   0,     1,  1,  1,   3,   2,    10,  0, 0,    0,    0,    0,    0,    0,   /* counters */
                    0,   0,   0,     0,  0,  0,   0,   0,    10,  0, 0,    0,    0,    0,    0,    0,   /* spares */
                ]
            }
        }
    },
    note1: {
        name: 'TEST: send a NOTE for the press duration',
        description: 'Description of the quick setup.',
        button: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: [0x0C, /*BUTTON*/ 0, /*HOLD_TIME*/ 1, /*LONG HOLD */ 0, /* NUM TAP */ 1, /* NUM HOLD */ 1, 0, 0, 0, 0, /*color*/ 4, 5, 0, 0, 0, 0, 0]
        },
        preset: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: {
                [ACTION.tap]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.tap,     /* Preset Action */
                    2, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* message types */
                    60, 0,0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* controllers */
                    1, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* channels */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* start values */
                    127,0,0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* end values */
                    1, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* steps */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* counters */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0  /* spares */
                ],
                [ACTION.release]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.release,     /* Preset Action */
                    2, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* message types */
                    60,0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* controllers */
                    1, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* channels */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* start values */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* end values */
                    1, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* steps */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0, /* counters */
                    0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0  /* spares */
                ],
            }
        }
    },
    ableton_looper: {
        name: 'TEST: Ableton looper',
        description: 'Description of the quick setup.',
        button: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: [0x0C, /*BUTTON*/ 0, /*HOLD_TIME*/ 1, /*LONG HOLD */ 0, /* NUM TAP */ 1, /* NUM HOLD */ 1, 0, 0, 0, 0, /*color*/ 4, 5, 6, 0, 0, 0, 0],
        },
        preset: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: {
                [ACTION.tap]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.tap,     /* Preset Action */
                      3, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* message types */
                     21, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* controllers */
                    1, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* channels */
                    127,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* start values */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* end values */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* steps */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* counters */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* spares */
                ],
                [ACTION.release]: [
                    0x0F,   /* preset */
                    0,     /* Preset Number */
                    ACTION.release,     /* Preset Action */
                      3, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* message types */
                     22, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* controllers */
                      1, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* channels */
                    127, 0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* start values */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* end values */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* steps */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* counters */
                    0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   0,   /* spares */
                ]
            }
        }
    },
    clear: {
        name: 'TEST: CLEAR ALL',
        description: 'Description of the quick setup.',
        global: [0x0D, MULTIJACK.tip, /*brightness*/ 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        button: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: null
            // [BUTTON.tip]: null,
            // [BUTTON.ring]: null
        },
        preset: {
            /* only one button supported in this version; must be index 0 */
            [BUTTON.footswitch]: {
                [ACTION.tap]: null,
                [ACTION.hold]: null,
                [ACTION.long_hold]: null
            }
            // [BUTTON.tip]: {
            //     [ACTION.tap]: null,
            //     [ACTION.hold]: null,
            //     [ACTION.long_hold]: null
            // },
            // [BUTTON.ring]: {
            //     [ACTION.tap]: null,
            //     [ACTION.hold]: null,
            //     [ACTION.long_hold]: null
            // }
        }
    }
    /*
        test1: {
            name: '_TEST_',
            global: [0x0D, MULTIJACK.fs, /!*brightness*!/ 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            button: {
                [BUTTON.footswitch]: [0x0C, /!*BUTTON*!/ 0, /!*HOLD_TIME*!/ 10, /!*LONG HOLD *!/ 100, /!* NUM TAP *!/ 4, /!* NUM HOLD *!/ 5, 16, 0, 0, 0, /!*color*!/ 4, 5, 6, 0, 0, 0, 0],
                [BUTTON.tip]: null,
                [BUTTON.ring]: null
            },
            preset: {
                [BUTTON.footswitch]: {
                    [ACTION.tap]: [
                        0x0F,   /!* preset *!/
                        0,     /!* Preset Number *!/
                        0,     /!* Preset Action *!/
                        0, 1, 1, 2,0,0,0,0,0,0,0,0,0,0,0,0, /!* message types *!/
                        0,10,20,60,0,0,0,0,0,0,0,0,0,0,0,0, /!* controllers *!/
                        1, 1, 2, 3,0,0,0,0,0,0,0,0,0,0,0,0, /!* channels *!/
                        0,11,21, 0,0,0,0,0,0,0,0,0,0,0,0,0, /!* start values *!/
                        0, 0, 0,10,0,0,0,0,0,0,0,0,0,0,0,0, /!* end values *!/
                        1, 1, 1, 1,0,0,0,0,0,0,0,0,0,0,0,0, /!* steps *!/
                        0, 1, 2, 3,0,0,0,0,0,0,0,0,0,0,0,0, /!* counters *!/
                        0, 0, 0, 0,0,0,0,0,0,0,0,0,0,0,0,0  /!* spares *!/
                    ],
                //     [ACTION.hold]: [],
                //     [ACTION.long_hold]: []
                },
                // [BUTTON.tip]: {
                //     [ACTION.tap]: [],
                //     [ACTION.hold]: [],
                //     [ACTION.long_hold]: []
                // },
                // [BUTTON.ring]: {
                //     [ACTION.tap]: [],
                //     [ACTION.hold]: [],
                //     [ACTION.long_hold]: []
                // }
            }
        },
    */
};
